import React, { useReducer, useState, useEffect } from 'react';
import Button from '../../CommonComponent/Button';
import Modal from 'react-bootstrap/Modal';
import Input from "../../CommonComponent/Input";
import { apiService } from '../../Services/ApiServices';
import InlineError from '../../CommonComponent/inlineError';
import { IoCloseCircle } from "react-icons/io5";
import BtnLoader from '../../CommonComponent/BtnLoader';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { validateEmail } from '../../CommonComponent/Validation';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import VerifyMobileNoModal from './VerifyMobileNoModal';

function EditPersonalDetails(props) {
  console.log("props________2______________________________", props)
  const logout = useLogout();
  const [btnLoader, setBtnLoader] = useState(false);
  const [firstName, setFirstName] = useState(props.user.first_name || '');
  const [lastName, setLastName] = useState(props.user.last_name || '');
  let usernameState = props.user.user_name;
  const [userName, setUserName] = useState((props.user.user_name ? (props.user.user_name) : ''));
  const [email, setEmail] = useState(props.user.email_address || '');

  const [countryCode, setCountryCode] = useState('+91');
  const [inputLoader, setInputLoader] = useState(false);
  const [isVarified, setVarifiedValue] = useState(props?.user?.user_name);
  const [isUserNameChanged, setIsUserNameChanged] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  let isMobileVarified = localStorage.getItem('isMobileVarified') ? localStorage.getItem('isMobileVarified') : false;

  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      phone: '',
      errorMsg: ''
    }
  );

  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      user: {}
    }
  );
  let phoneee = localStorage.getItem('mobile') ? localStorage.getItem('mobile') : props.user.phone_number || '';
  const [phone, setPhone] = useState(phoneee);

  const [isFormValid, setIsFormValid] = useState(false);
  const handleFirstNameChange = e => {
    const textInput = e.target.value;
    // Use a regular expression to check if the input contains only letters
    if (/^[a-zA-Z]+$/.test(textInput) || textInput === '') {
      if ((textInput.length > 0) && (textInput.length < 2)) {
        setFirstName(textInput);
        setError({ firstName: 'First Name must be between 2 and 30 characters' });
      } else {
        setFirstName(textInput);
        if (textInput.length == 0) {
          setError({ firstName: 'First Name is required' });
        }
        else {
          setError({ firstName: '' });
        }
        setBtnLoader(false);
      }

    } else {
      // setisAllAdded(true)
    }
  };

  const handleInputLastName = e => {
    const textInput = e.target.value;
    // Use a regular expression to check if the input contains only letters
    if (/^[a-zA-Z]+$/.test(textInput) || textInput === '') {
      if ((textInput.length > 0) && (textInput.length < 2)) {
        setLastName(textInput);
        setError({ lastName: 'Last Name must be between 2 and 30 characters' });
      } else {
        setLastName(textInput);
        if (textInput.length == 0) {
          setError({ lastName: 'Last Name is required' });
        }
        else {
          setError({ lastName: '' });
        }
        setBtnLoader(false);
      }

    } else {
      // setisAllAdded(true)
    }
  };

  const handleSubmit = async () => {
    let hasError = false;
    // if (!validateEmail(email).status) {
    //   setError({ email: 'Please enter a valid email address.' });
    //   hasError = true;
    // } else {
    //   setError({ email: '' });
    // }

    if (hasError) {
      return;
    }
    setBtnLoader(true);
    const token = localStorage.getItem('token');
    const data = {
      first_name: firstName,
      last_name: lastName, user_name: (userName ? userName : ''),
      phone_number: parseInt(phone),
      country_code: countryCode,
    };

    console.log("username", userName)
    if ((userName == null) || !userName)
      props.varifyUsername(false)
    try {
      const res = await apiService.editUserDetails(data, token);
      if (res?.data?.status_code === 1) {
        localStorage.removeItem("mobile");
        props.updateUserDetails();
        props.onlyClose();
      } else {
        // Handle the error response
        if (res?.data?.status_code != 1) {
          notify("err", res?.data?.error?.message?.error);
          if (res?.data?.status_code == 2)
            logout();
          if (res?.data?.status_code == 5)
            logout();
          if (res?.data?.status_code == 4)
            logout();
        }
      }
    } catch (error) {
      // Handle the catch error
    } finally {
      setBtnLoader(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value).status) {
      setError({ email: 'Please enter a valid email address.' });
    } else {
      setError({ email: '' });
    }
  };

  const handleInputChange = (e) => {
    setIsUserNameChanged(true);
  
    usernameState = '';
    var value = e.target.value;
    const isValid = /^[a-zA-Z]+$/.test(value);
    if (isValid && userName.trim() === '') {
      setVarifiedValue(false)
      console.log("1111111111111111111111111111111111111")
      if (userName.length < 5 || userName.length > 30) {
        setError({ userName: "Username must be at least 5 characters long" });
      }
      setInputLoader(true);
      // const lowercaseValue = e.target.value.toLowerCase();
      const lowercaseValue = e.target.value;
      setUserName(lowercaseValue);
    } else {
      console.log("222222222222222222222222222222222222222")
      if (userName === "@") {
        setVarifiedValue(false)
        setInputLoader(true);
        if (userName.length == 1) {
          setError({ userName: "Username is required" });
        } else if (userName.length < 5 || userName.length > 30) {
          setError({ userName: "Username must be at least 5 characters long" });
        } else {
          setError({ userName: "" });
        }
      }
      // var lowercaseValue = e.target.value.toLowerCase();
      var lowercaseValue = e.target.value;
      let replaceValue = lowercaseValue;
      if (value.length > 1) {
        replaceValue = value;
      }
      const isValidS = /^[a-zA-Z]+$/.test(replaceValue);
      if (isValidS) {
        setVarifiedValue(false)
        console.log("3333333333333333333333333333333")
        setUserName(lowercaseValue.replace(/\s/g, ""));
      } else {
        console.log("4444444444444444444444444444", value.length,value)
        if (value.length === 0) {
          setError({ userName: "" });
          setUserName("");
        }else{
          
        }
      }
    }
  };

  useEffect(() => {
    if (!usernameState && (usernameState != null) && (usernameState != '')) {
      usernameState = ''
      setUserName('')
    }
  }, [userName])


  useEffect(() => {
    setInputLoader(false);
    if ((isUserNameChanged == true) && (userName.length >= 5 && userName.length <= 30)) {
      setInputLoader(true);
      setVarifiedValue(false);
      setError({ userName: '' });

      const fetchData = async () => {
        setInputLoader(true);
        try {
          const req = { username: userName };
          const token = localStorage.getItem('token');
          const response = await apiService.checkUsername(req, token);
          if (response.data?.status_code === 1) {
            if (response.data?.response_data?.username_available) {
              setVarifiedValue(true);
              setUserName(req.username);
              setInputLoader(false);
              setError({ userName: '' });
            } else {
              setInputLoader(false);
              setError({ userName: response.data?.response_data?.detail });
            }
          } else {
            setError({ userName: response?.data?.error.message.error });
            setInputLoader(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const timeoutId = setTimeout(() => {
        if (userName.trim() !== '') {
          fetchData();
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    } else {
      if (userName.length < 5 || userName.length > 30) {
        if (!userName || (userName.length == 0)) {
          setError({ userName: '' })
        } else {
          setError({ userName: "Username must be at least 5 characters long" });
        }
      }
    }
  }, [userName, isUserNameChanged]);

  useEffect(() => {
    // Check if all fields are valid
    const isValidForm = !error.firstName && !error.phone && !error.userName && !error.phone
      && firstName && lastName && (userName || (userName.length == 0)) && phone && ((phone === props.user.phone_number) || (isMobileVarified == 'true'));

    setIsFormValid(isValidForm);
  }, [error, firstName, lastName, userName, phone, isMobileVarified]);

  const checkPhoneVerify = () => {
    try {
      if (handleValidatePhone() == true) {
        const token = localStorage.getItem('token');
        let req = {
          phone_number: parseInt(phone),
          country_code: countryCode,
          is_for_forgot_password: 0
        }
        // setUserInput({ isLoader: true })
        apiService.sendOtpNew(req, token).then(result => {
          if (result.data?.status_code == 0) {
            setError({ errorMsg: result?.data?.error.message.error });
            setModalShow(false)
          }
          else if (result.data?.response_data["number_verified"] === false) {
            setError({ errorMsg: '' });
            localStorage.setItem('phone_number', phone);
            localStorage.setItem('country_code', countryCode);
            localStorage.setItem('isMobileVarified', false);
            // setModalShow(true);
            props.onlyOpenVerify()
          } else if (result.data?.response_data["number_verified"] === true) {
            setError({ errorMsg: '' });
            localStorage.setItem('phone_number', phone);
            localStorage.setItem('country_code', countryCode);
            localStorage.setItem('isMobileVarified', false);
          } else {
            setError({ errorMsg: result?.data?.error.message.error });
          }
        })
      }
    } catch (e) {
      console.error('e', e);
    }
  }


  const handleValidatePhone = () => {
    let validate = true;
    setError({});
    if ((phone == undefined) || (phone == '')) {
      setError({ mobile: 'Phone is require' });
      validate = false
    }
    return validate;
  }

  const handleInput = (event) => {
    const sanitizedValue = event.target.value.replace(/\D/g, ''); // Remove non-digits
    let formattedMobileNumber = sanitizedValue;

    // Check if the first digit is 0
    if (formattedMobileNumber.startsWith('0')) {
      // If the first digit is 0, ensure there are 11 digits
      formattedMobileNumber = formattedMobileNumber.slice(0, 11);
    } else {
      // If the first digit is not 0, ensure there are 10 digits
      formattedMobileNumber = formattedMobileNumber.slice(0, 10);
    }
    // setisPhone(false);
    setPhone(formattedMobileNumber);
    localStorage.removeItem("isMobileVarified");
    if ((formattedMobileNumber.length < 10) && (formattedMobileNumber.length > 0)) {
      setError({ errorMsg: 'Please enter 10 digit phone number' });
    } else {
      if (formattedMobileNumber.length >= 10) {
        setError({ errorMsg: '' });
        // setisPhone(true);
      } else {
        setError({ errorMsg: 'Phone number is required' });
      }
    }
  };


  const onlyClose = (state) => {
    props.onlyClose()
    // setModalShow(state)
  }

  // const token = localStorage.getItem('token');

  // const UserDetails = () => {
  //   // setUserInput({ isLoader: true });
  //   apiService.getUserDetails(token).then(res => {
  //     if (res?.data?.status_code === 1) {
  //       setUserInput({ user: res?.data?.response_data.user, isLoader: false });
  //     } else {
  //       if (res?.data?.status_code != 1) {
  //         // notify("err", res?.data?.error?.message?.error);
  //         logout();
  //       }
  //     }
  //   }).catch(() => {
  //     setUserInput({ isLoader: false });
  //   });
  // };

  // useEffect(() => {
  //   UserDetails();
  // }, []);

  console.log("isVarified", isVarified)


  function isMobileVerfy() {
    props.onlyClose()
    props.varifyUsername(false)
    localStorage.setItem('isMobileVarified', true);
  }

  return (
    <Modal
      {...props}
      size="lg"
      className='cstm_modal_edit_product '
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className='close_icon _add_product' onClick={isMobileVerfy}><span><IoCloseCircle /></span></div>
        <span className='schedl_action avenir_black'>
          Edit Personal Details
        </span>
        <div className='row'>
          <div className='col-sm-6'>
            <label className="form-label">First Name</label>
            <Input
              placeholder="First Name"
              className="first_name_inpt form-control"
              value={firstName}
              name="first_name"
              maxLength={30}
              minLength={2}
              onChange={(e) => handleFirstNameChange(e)}
            />
            <InlineError className='store_error' errorMessage={error.firstName} />
          </div>
          <div className='col-sm-6'>
            <label className="form-label">Last Name</label>
            <Input
              placeholder="Last Name"
              className="last_name_inpt form-control"
              value={lastName}
              name="last_name"
              maxLength={30}
              minLength={2}
              onChange={(e) => handleInputLastName(e)}
            />
            <InlineError className='store_error' errorMessage={error.lastName} />
          </div>
        </div>
        <div className='col-sm-12 mt-4'>
          <label className="form-label">Username</label>
          <div className='d-flex'>
            <Input
              type="text"
              name="username"
              className="usrname_txt  pt-0"
              value={userName}
              placeholder={'Username'}
              maxLength={30}
              minLength={5}
              onChange={(e) => handleInputChange(e)}
            />

            {isVarified ? (
              <span className='phn_verify'><img src='assets/check.svg' alt="Verified" /></span>
            ) : (
              inputLoader && <Spinner animation="border" variant="secondary" className='inpt_loader' />
            )}
          </div>

          <div className='usrnm_err ps-0'>
            <InlineError errorMessage={error.userName} />
          </div>
        </div>
        {/* <div className=' mt-4'>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              value={email}
              name="email"
              onChange={handleEmailChange}

            />
            <InlineError className='store_error' errorMessage={error.email} />
          </Form.Group>
        </div> */}
        <div className=' mt-4'>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <div className='wrap_inpt wrap_option position_relative mt-0'>
              <span className='county_code'>🇮🇳 +91</span>
              <Form.Control
                type="text"
                placeholder="Phone Number"
                value={`${phone}`}
                name="phone"
                onChange={handleInput}
                className='textbox_leftpading'
              />
              {
                (isMobileVarified == 'true') ?
                  <span className='phn_verify'><img src='assets/check.svg' /></span>
                  :
                  ((phone.length == 10) || (phone.length == 11)) ?
                    <span className='verify_phn_no cursor_pointer' onClick={checkPhoneVerify}  >Verify</span>
                    :
                    <span className='verify_phn_no cursor_pointer' ></span>
              }
            </div>
            <div className='usrnm_err ps-0'>
              <InlineError errorMessage={error.errorMsg} />
            </div>
          </Form.Group>
        </div>

        <div className='text-center mt-4'>
          <Button
            name={btnLoader ? <BtnLoader /> : "Submit"}
            onClick={handleSubmit}
            disabled={!isFormValid}

          />
        </div>
        {/* 
        {modalShow && <VerifyMobileNoModal show={modalShow} onHide={() => setModalShow(false)}
          backdrop="static"
          keyboard={false} 
          onlyClose={onlyClose}
          // onlyClose1={props.onlyClose()}
          />} */}
      </Modal.Body>
    </Modal>
  );
}

export default EditPersonalDetails;
