import React from 'react';
import advantage1 from '../../src/assets/landing/advantage1.svg';
import advantage2 from '../../src/assets/landing/advantage2.svg';
import advantage3 from '../../src/assets/landing/advantage3.svg';

function BidsBoxxAdvantage() {
  return (
    <div className='_advantage'>
        <h1 className='heading'>  BidsBoxx 
           <p> ADVANTAGES</p>
        </h1>
        <div className='advantage_block_parent'>
            {advantage?.map((item)=>{
                return(
                <div className='advantage_block'>
                    <div className='_img'>
                        <img src={item.img} alt="image"/>
                    </div>
                    <div className='_hading'>{item.heading}<br/>{item.heading1}</div>
                    <div className='_discription'>{item.discription}</div>
               </div>
                )
            })}
          
        </div>
      
    </div>
  )
}

export default BidsBoxxAdvantage

const advantage=[
    {
        img:advantage1,
        heading:"COMPETITIVE ",
        heading1:" PRICING",
        discription:"Bargaining platforms spark supplier competition, slashing prices. Buyers benefit from cost savings by choosing the best offer."
    },
    {
        img:advantage2,
        heading:"TRANSPARENT",
        heading1:" PROCESS",
        discription:"Buyers can evaluate and select the most suitable offer, promoting trust and fairness."
    },
    {
        img:advantage3,
        heading:"ACCESS TO A WIDE",
        heading1:"RANGE OF SUPPLIERS",
        discription:"Bargaining platforms expand buyer access to diverse suppliers, fostering discovery and better deals."
    }
]