import React, { useState, useReducer, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { LiaEditSolid } from "react-icons/lia";
import { ProductImage } from '../../CommonComponent/ImageConstants';
import { apiService } from '../../Services/ApiServices';
import EditProductModal from './EditProductModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { confirmAlert } from 'react-confirm-alert';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';

function ProductDetailsModal(props) {
    const logout = useLogout();
    const [inputLoader, setInputLoader] = useState(false);
    const token = localStorage.getItem('token');
    const [modalEditProduct, setModalEditProduct] = useState(false);
    const [getCategory, setCategory] = useState('');
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: true,
            list: {},
            isResponse: '',
            search: '',
            searchType: '',
            inactive: '',
            bP: '',
            totalCount: '',
            activePage: 1,
            dir: '',
            sortValue: '',
            type: '',
            image: ''

        }
    );

    useEffect(() => {
        if (props.productId)
            getProduct()
    }, [props.productId])

    const hideProductModalPopup = () => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure you want to discard your changes?',
            buttons: [
                {
                    label: 'YES',
                    onClick: () => setModalEditProduct(false)
                },
                {
                    label: 'NO',
                }
            ]
        });

    }

    const onlyClose = () => {
        setModalEditProduct(false)
    }

    const getProduct = () => {
        setUserInput({ isLoader: true });
        let productId = props.productId;
        apiService.getProduct(productId, token).then(res => {
            if (res?.data?.status_code == 1) {
                let data = res?.data?.response_data.product_details;
                setUserInput({ data: res?.data?.response_data.product_details, totalCount: res?.data.response_data.count, isLoader: false });
                const categoryName = props?.categories?.find(categoryItem =>
                    data?.category == categoryItem.id.toString()
                )?.name;

                setCategory(categoryName);
            }
            else {
                if (res?.data?.status_code != 1) {
                    notify("err", res?.data?.error?.message?.error);
                    if (res?.data?.status_code == 2)
                        logout();
                    if (res?.data?.status_code == 5)
                        logout();
                    if (res?.data?.status_code == 4)
                        logout();
                }
            }
        })
    }

    const openProductEditPopup = () => {
        setModalEditProduct(true)
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                className='cstm_modal_product _product_detail_modal ___hide_mobile_modals'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={false}
                backdropClassName='___hide_modal_mobile'
            >
                <Modal.Header>
                    <div className=''><p className='mb-0'>Status:</p>  <p className='mb-0'>{(userInput.isLoader == true) ? "" : (userInput.data.is_linked ? "Scheduled for Auction" : "Not Scheduled for Auction")}</p></div>
                    <div className='text-center'><h4> Product Detail</h4></div>
                    <div className='_btn text-end'><button className='btn btn-outline-primary btn-edit' onClick={openProductEditPopup}>Edit<span className='_icon'><LiaEditSolid /></span></button></div>
                </Modal.Header>
                <Modal.Body>
                    {
                        (userInput.isLoader == true) ?
                            <div>
                                <div >
                                    <Skeleton width={630} height={15} count={17} />
                                </div>
                            </div>
                            :
                            <>

                                <div className='_detail_page'>
                                    <div className='_image'>

                                        {userInput.data.image ? <img src={userInput.data.image} alt="product" height={400} width={180} /> : <img src={ProductImage} alt="product" height={400} width={180} />}
                                    </div>
                                    <div className='_text'>
                                        <p><span>Product Name</span>:<span>{userInput.data.name}</span></p>
                                        <p><span>Product Description</span>:<span>{userInput.data.description}</span></p>
                                        <p><span>Category</span>:<span>{getCategory}</span></p>
                                        <p><span>No exchange/No refund</span>:<span>{((userInput.data.no_exchange_refund) == true) ? "Yes" : "No"}</span></p>
                                        <p><span>Original MRP</span>:<span>₹{userInput.data.original_mrp}</span></p>
                                        <p><span>Auction Start Price</span>:<span>₹{userInput.data.auction_start_price}</span></p>
                                        <p><span>Bid Amount Boost</span>:<span>₹{userInput.data.bid_amount_boost}</span></p>
                                        {
                                           ( userInput?.data?.auction &&  userInput?.data?.is_linked) ?
                                                <p><span>Auction</span>:<span>{userInput.data.auction.description}</span></p>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                                {modalEditProduct && <EditProductModal
                                    show={modalEditProduct}
                                    onHide={hideProductModalPopup}
                                    onlyClose={onlyClose}
                                    onHide1={props.onHide1}
                                    productId={props?.productId}
                                    getProduct={getProduct}
                                    categories={props?.categories}
                                    updatedProductList={props.updatedProductList}
                                />}
                            </>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProductDetailsModal

