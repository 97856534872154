import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FilterIcon } from '../Svg';
import Button from '../CommonComponent/Button';

function FilterBox(props) {
  const [category, setSelectedCategories] = useState([]);
  const [isDropdown, SetIsDropdown] = useState(false);

  const categoryList = [
    ...props.getCategory,
    { id: 0, name: "Product not scheduled for auction" },
  ].filter((category) => category.name);;

  console.log("categoryList____________________________", categoryList)

  const onChangeCategory = useCallback((e) => {
    const { value, checked } = e.target;
    const parsedValue = parseInt(value);
    if (isNaN(parsedValue)) return;

    setSelectedCategories((prevCategories) =>
      checked
        ? prevCategories.includes(parsedValue)
          ? prevCategories
          : [...prevCategories, parsedValue]
        : prevCategories.filter((cat) => cat !== parsedValue)
    );
  }, []);

  useEffect(() => {
    setSelectedCategories(Array.isArray(props.getCategory) ? [...props.getCategory] : []);
  }, [props.getCategory]);

  const dropdownHendelor = () => SetIsDropdown((prevState) => !prevState);

  const renderCheckbox = (catg) => (
    <div key={catg.id} className="dropdown-item">
      <div className="form-group">
        <input
          type="checkbox"
          id={`category${catg.id}`}
          name={`category${catg.id}`}
          value={catg.id}
          checked={category.includes(catg.id)}
          onChange={onChangeCategory}
        />
        <label htmlFor={`category${catg.id}`}>
          <span>{catg.name}</span>
        </label>
      </div>
    </div>
  );

  return (
    <div className="_filter_box">
      <Dropdown data-bs-theme="" show={isDropdown} autoClose="outside" onToggle={() => SetIsDropdown(!isDropdown)} >
        <Dropdown id="dropdown-button-dark-example1" variant="" onClick={dropdownHendelor}>
        <span></span>
          <FilterIcon />
        </Dropdown>
        <Dropdown.Menu>
          <button type="button" aria-expanded="false"  className="dropdown-togglee">
            <span>Select Categories</span>
          </button>
          {categoryList.map((catg) => renderCheckbox(catg))}
          <div className="dropdown-item">
            <Button
              name="APPLY"
              className="btn btn-sm lower_case"
              onClick={() => {
                SetIsDropdown(false);
                props.productList(category);
              }}
            />
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default FilterBox;
