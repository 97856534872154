import React, { useEffect, useState } from 'react';
import { apiService } from '../Services/ApiServices';
import { Dropdown } from 'react-bootstrap';
import { FaRegClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNotification, hideNotification } from '../Component/Redux/Sliceses/notificationSlice';
import { useLocation } from 'react-router-dom';


function Header(props) {
    const location = useLocation();
    // console.log("props__________", props, location.pathname)
    localStorage.removeItem("categories_list");
    let isMeeting = localStorage.getItem('isMeeting') ? true : false;
    const [getIsMeeting, setIsMeeting] = useState(isMeeting);
    const token = localStorage.getItem('token');
    const [isToggled, setIsToggled] = useState(false);
    const visible = useSelector((state) => state.notification.visible);
    const dispatch = useDispatch();
    const NotificationToggle = () => {
        dispatch(toggleNotification())
    }

    

    const dropdownHandler = () => {
        setIsToggled(false);
        try {
            apiService.notificationRead(token)
        } catch (e) {
            console.error('e', e);
        }
    };

    useEffect(() => {
        setIsToggled(props.isViewSeller);
    }, [props.isViewSeller])

    const endMeetingSession = () => {
        props?.stopMeetingSessionAwsChime();
        props?.endMeetingSocket(localStorage.getItem("auctionId"));
    }

    useEffect(() => {
        setIsMeeting(localStorage.getItem('isMeeting') &&  location.pathname == '/live' ? true : false)
    }, [])
    

    const CloseNotification = () => {
        dispatch(hideNotification());
    }
    useEffect(() => {
        CloseNotification()
    }, [])

    return (
        <div className='header_wrp'>
            <div className='header_logo'>
                <img src='https://d2rqcufsarnep3.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "47px" }} />
            </div>
            <div className='header_usrname'>
                {getIsMeeting && (
                    <button className="btn end_action lower_case common_button avenir_heavy" onClick={() => endMeetingSession()}>
                        End Auction
                    </button>
                )}
                {!props.onlylogo &&
                    <>
                        <div>
                            <span className='avenir_heavy'> <a className='header_usrname_anchor' href="/store"> <img src={(sessionStorage.getItem('logo'))} height={40} width={40} />   </a>{(sessionStorage.getItem('name'))}</span>
                        </div>
                        <div className='_notification'>
                            <Dropdown data-bs-theme=""
                                show={visible}
                                autoClose="inside"
                                onToggle={NotificationToggle}
                            >
                                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="">
                                    {(isToggled == true) ? (
                                        <img src='https://d2rqcufsarnep3.cloudfront.net/temp/images/notification.svg' alt="notification" onClick={dropdownHandler} />
                                    ) : (
                                        <img src='https://d2rqcufsarnep3.cloudfront.net/temp/images/notification_gray.svg' alt="notification" />
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='_notification_box'>
                                    <div className='_heading'>
                                        <h5>Notification</h5>
                                    </div>
                                    <div className='_body'>
                                        {
                                            props.noticationList?.map((item) => {
                                                return (
                                                    <>
                                                        {item.type === 1 ?
                                                            <Dropdown.Item className="dropdown_item _order">
                                                                <h5>Payment failed<span > {item?.mid_content}</span></h5>
                                                                <h4>{item?.end_content}</h4>
                                                                <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                            </Dropdown.Item>
                                                            : item.type === 2 ?
                                                                <Dropdown.Item className="dropdown_item _order">
                                                                    <h5>Payment Complete<span > {item?.mid_content}</span></h5>
                                                                    <h4>{item?.end_content}</h4>
                                                                    <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                </Dropdown.Item>
                                                                : item.type === 3 ?
                                                                    <Dropdown.Item className="dropdown_item _schdule_auction">
                                                                        <h5>Auction Schduled at<span> {item?.mid_content}</span></h5>
                                                                        <h4><span><FaRegClock /></span>  {item?.end_content}</h4>
                                                                        <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                    </Dropdown.Item>
                                                                    : item.type === 4 ?
                                                                        <Dropdown.Item className="dropdown_item _order">
                                                                            <h5>Auction Winner<span > {item?.mid_content}</span></h5>
                                                                            <h4>{item?.end_content}</h4>
                                                                            <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                        </Dropdown.Item>
                                                                        : item.type === 5 ?
                                                                            <Dropdown.Item className="dropdown_item _order">
                                                                                <h5>Giveaway Winner<span > {item?.mid_content}</span></h5>
                                                                                <h4>{item?.end_content}</h4>
                                                                                <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                            </Dropdown.Item>
                                                                            : item.type === 9 ?
                                                                                <Dropdown.Item className="dropdown_item _order">
                                                                                    <Link to={`order-detail/${item?.order_id}`}>
                                                                                        <h5>Buyer requested cancellation for order <span > {item?.mid_content}</span></h5>
                                                                                        <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                    </Link>
                                                                                </Dropdown.Item>
                                                                                : item.type === 7 ?
                                                                                    <Dropdown.Item className="dropdown_item _order">
                                                                                        <Link to={`order-detail/${item?.order_id}`}>
                                                                                            <h5><span> {item?.mid_content}</span></h5>
                                                                                            <h4>{item?.end_content}</h4>
                                                                                            <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    : item.type === 8 ?
                                                                                        <Dropdown.Item className="dropdown_item _order">
                                                                                            <Link to={`order-detail/${item?.order_id}`}>
                                                                                                <h5>Return<span > {item?.mid_content}</span></h5>
                                                                                                <h4>{item?.end_content}</h4>
                                                                                                <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                        : item.type === 20 ?
                                                                                            <Dropdown.Item className="dropdown_item _order">
                                                                                                <Link to={`order-detail/${item?.order_id}`}>
                                                                                                    <h5><span > {item?.mid_content}</span></h5>
                                                                                                    <h4>{item?.end_content}</h4>
                                                                                                    <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                                </Link>
                                                                                            </Dropdown.Item>
                                                                                                : item.type === 21 ?
                                                                                                <Dropdown.Item className="dropdown_item _order">
                                                                                                    <Link to={`order-detail/${item?.order_id}`}>
                                                                                                        <h5><span > {item?.mid_content}</span></h5>
                                                                                                        <h4>{item?.end_content}</h4>
                                                                                                        <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                                    </Link>
                                                                                                </Dropdown.Item>
                                                                                                    : item.type === 22 ?
                                                                                                    <Dropdown.Item className="dropdown_item _order">
                                                                                                        <Link to={`order-detail/${item?.order_id}`}>
                                                                                                            <h5><span > {item?.mid_content}</span></h5>
                                                                                                            <h4>{item?.end_content}</h4>
                                                                                                            <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                                        </Link>
                                                                                                    </Dropdown.Item>
                                                                                                        : item.type === 23 ?
                                                                                                        <Dropdown.Item className="dropdown_item _order">
                                                                                                            <Link to={`order-detail/${item?.order_id}`}>
                                                                                                                <h5><span > {item?.mid_content}</span></h5>
                                                                                                                <h4>{item?.end_content}</h4>
                                                                                                                <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                                            </Link>
                                                                                                        </Dropdown.Item>
                                                                                            : item.type === 6 ?
                                                                                                <Dropdown.Item className="dropdown_item _order">
                                                                                                    <Link to={`order-detail/${item?.order_id}`}>
                                                                                                        <h5>Your order is cancelled for <span >{item?.mid_content}</span></h5>
                                                                                                        <p>{moment(item?.created_at).format('YYYY-MM-DD')} at {moment(item?.created_at).format('hh:mm A')}</p>
                                                                                                    </Link>
                                                                                                </Dropdown.Item>
                                                                                                : ''
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Header