import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const CloseIcon = () =>
    <svg width="22" height="21.994" viewBox="0 0 22 21.994">
        <path d="M3.222,18.777A11.016,11.016,0,1,1,11,21.995,10.93,10.93,0,0,1,3.222,18.777ZM4.427,4.423A9.31,9.31,0,1,0,11,1.7,9.241,9.241,0,0,0,4.427,4.423ZM13.47,14.559l-2.414-2.411L8.641,14.559a.852.852,0,1,1-1.205-1.206l2.413-2.409L7.436,8.535A.852.852,0,1,1,8.641,7.329L11.056,9.74,13.47,7.329a.852.852,0,0,1,1.205,1.206l-2.413,2.409,2.413,2.409a.852.852,0,0,1-1.205,1.206Z" transform="translate(0 0)" fill="#696c6f"/>
    </svg>
export const FilterIcon=()=>
<svg  width="30.244" height="25.591" viewBox="0 0 30.244 25.591">
  <path  d="M4.163,9.653H8.816a2.329,2.329,0,0,0,2.326,2.326h2.326A2.329,2.329,0,0,0,15.8,9.653H32.081a1.163,1.163,0,0,0,0-2.326H15.8A2.329,2.329,0,0,0,13.469,5H11.143A2.329,2.329,0,0,0,8.816,7.326H4.163a1.163,1.163,0,0,0,0,2.326Z" transform="translate(-3 -5)" fill="#0e1624"/>
  <path  d="M32.081,15.326H25.1A2.329,2.329,0,0,0,22.775,13H20.449a2.329,2.329,0,0,0-2.326,2.326H4.163a1.163,1.163,0,1,0,0,2.326H18.122a2.329,2.329,0,0,0,2.326,2.326h2.326A2.329,2.329,0,0,0,25.1,17.653h6.979a1.163,1.163,0,0,0,0-2.326Z" transform="translate(-3 -3.694)" fill="#0e1624"/>
  <path  d="M32.081,23.326H15.8A2.329,2.329,0,0,0,13.469,21H11.143a2.329,2.329,0,0,0-2.326,2.326H4.163a1.163,1.163,0,1,0,0,2.326H8.816a2.329,2.329,0,0,0,2.326,2.326h2.326A2.329,2.329,0,0,0,15.8,25.653H32.081a1.163,1.163,0,0,0,0-2.326Z" transform="translate(-3 -2.388)" fill="#0e1624"/>
</svg>

export const UserIcon = ()=>
<svg  width="27.862" height="27.86" viewBox="0 0 27.862 27.86">
  <g  data-name="Group 72734" transform="translate(0 0)">
    <g >
      <path data-name="Path 40" d="M13.931,0a13.93,13.93,0,1,0,13.931,13.93A13.931,13.931,0,0,0,13.931,0Zm0,4.165A4.608,4.608,0,1,1,9.324,8.774,4.608,4.608,0,0,1,13.931,4.166Zm0,20.053A10.224,10.224,0,0,1,7.27,21.764a1.964,1.964,0,0,1-.689-1.492,4.647,4.647,0,0,1,4.67-4.646h5.362a4.64,4.64,0,0,1,4.662,4.646,1.959,1.959,0,0,1-.688,1.492A10.22,10.22,0,0,1,13.928,24.219Z" transform="translate(0 -0.001)" fill="#2f2f2f"/>
    </g>
  </g>
</svg>
export const Order = ()=>
<svg width="47" height="47" viewBox="0 0 47 47">
  <g id="Group_98372" data-name="Group 98372" transform="translate(-0.061 -0.049)">
    <circle id="Ellipse_893" data-name="Ellipse 893" cx="23.5" cy="23.5" r="23.5" transform="translate(0.061 0.049)"/>
    <g id="box_1_" data-name="box (1)" transform="translate(10.755 8.966)">
      <path id="Path_215541" data-name="Path 215541" d="M55.223,25.981V13.807a.447.447,0,0,0-.247-.4L44.258,8.049a.448.448,0,0,0-.387-.006L32.26,13.4a.445.445,0,0,0-.26.405V28.1a.445.445,0,0,0,.26.405l11.611,5.359a.447.447,0,0,0,.387-.006l2.9-1.45a5.359,5.359,0,1,0,8.067-6.427Zm-6.51-14.706-9.548,5.141-2.446-1.129,9.813-5.1ZM38.7,17.185V24.3l-2.233-.957V16.154ZM44.05,8.944l1.494.747-9.731,5.06a.454.454,0,0,0-.1.072l-2.2-1.015ZM43.611,32.76,32.893,27.813V14.505l2.68,1.237v7.89a.447.447,0,0,0,.271.41l3.126,1.34a.439.439,0,0,0,.176.036.447.447,0,0,0,.447-.447V17.6l4.019,1.855Zm.439-14.089-3.871-1.786,9.511-5.121,4.088,2.044Zm2.827,12.878L44.5,32.735V19.442L54.33,14.53V25.46a5.36,5.36,0,0,0-7.453,6.089ZM52.1,34.8a4.466,4.466,0,1,1,4.466-4.466A4.466,4.466,0,0,1,52.1,34.8Z" transform="translate(-32 -8.002)" fill="#eff0f2"/>
      <path id="Path_215542" data-name="Path 215542" d="M356.15,384.131l-2.364,2.364-1.024-1.024a.447.447,0,1,0-.632.632l1.34,1.34a.446.446,0,0,0,.632,0l2.68-2.68a.447.447,0,0,0-.632-.632Z" transform="translate(-334.136 -363.01)" fill="#eff0f2"/>
    </g>
  </g>
</svg>
export const SignOut = ()=>
<svg width="27.352" height="30.604" viewBox="0 0 27.352 30.604">
  <path id="logout_1_" data-name="logout (1)" d="M40.66,25.73v1.525a3.265,3.265,0,0,1-1.669,2.89,3.264,3.264,0,0,1-3.337,0L28.873,26.23a3.265,3.265,0,0,1-1.669-2.89v-20A3.341,3.341,0,0,1,30.542,0H45.477a3.341,3.341,0,0,1,3.338,3.338V7.554a1.1,1.1,0,1,1-2.192,0V3.338a1.148,1.148,0,0,0-1.146-1.146H32.39L38.991,6a3.265,3.265,0,0,1,1.669,2.89V23.538h4.817a1.147,1.147,0,0,0,1.146-1.146V18.7a1.1,1.1,0,1,1,2.192,0v3.7a3.341,3.341,0,0,1-3.338,3.338ZM50.815,13.961l-1.207,1.207a1.1,1.1,0,0,0,1.55,1.55l3.077-3.078a1.1,1.1,0,0,0,0-1.549L51.158,9.013a1.1,1.1,0,1,0-1.55,1.549l1.207,1.207H42.73a1.1,1.1,0,0,0,0,2.192h8.085Z" transform="translate(-27.204 0)" fill="#fff" fill-rule="evenodd"/>
</svg>


export const Coin = ()=>
<svg  width="27.443" height="21.191" viewBox="0 0 27.443 21.191">
  <g id="coin" transform="translate(-0.5 -58.709)">
    <g id="Group_97844" data-name="Group 97844" transform="translate(7.572 69.303)">
      <path id="Path_215343" data-name="Path 215343" d="M132.188,259.6c0-2,4.56-3.63,10.186-3.63s10.185,1.626,10.185,3.63v3.337c0,2-4.56,3.63-10.185,3.63s-10.186-1.626-10.186-3.63Z" transform="translate(-132.188 -255.971)" fill="#fe9923" fill-rule="evenodd"/>
    </g>
    <g id="Group_97845" data-name="Group 97845" transform="translate(7.572 69.303)">
      <path id="Path_215344" data-name="Path 215344" d="M142.375,255.971c5.625,0,10.185,1.626,10.185,3.63s-4.56,3.63-10.185,3.63-10.186-1.625-10.186-3.63S136.749,255.971,142.375,255.971Z" transform="translate(-132.189 -255.971)" fill="#fecd3d" fill-rule="evenodd"/>
    </g>
    <g id="Group_97846" data-name="Group 97846" transform="translate(9.655 70.999)">
      <path id="Path_215345" data-name="Path 215345" d="M179.064,287.558c4.475,0,8.1.867,8.1,1.934s-3.627,1.934-8.1,1.934-8.1-.865-8.1-1.934S174.589,287.558,179.064,287.558Z" transform="translate(-170.96 -287.558)" fill="#fea832" fill-rule="evenodd"/>
    </g>
    <path id="Path_215346" data-name="Path 215346" d="M170.385,399.71l.87,0v1.69c-.3-.048-.587-.1-.87-.162Zm12.587-.9v1.776q.471-.18.872-.38v-1.4Zm-9.441,2.852c.287.022.578.037.872.048V400.1l-.872,0Zm-6.294-1.456q.4.2.872.38v-1.778l-.872,0Zm9.441,1.5c.3-.011.584-.028.87-.048V400.1l-.87,0Zm3.148-.314q.447-.073.87-.162v-1.528l-.87,0Z" transform="translate(-157.782 -321.839)" fill="#c85929"/>
    <g id="Group_97847" data-name="Group 97847" transform="translate(1.638 76.966)">
      <path id="Path_215347" data-name="Path 215347" d="M23.543,398.667v1.081h1.079v.772H23.543V401.6h-.772V400.52H21.69v-.772h1.081v-1.081Z" transform="translate(-21.69 -398.667)" fill="#fea832"/>
    </g>
    <g id="Group_97848" data-name="Group 97848" transform="translate(21.387 65.128)">
      <path id="Path_215348" data-name="Path 215348" d="M391.277,178.224V179.3h1.081v.77h-1.081v1.081h-.772v-1.081h-1.079v-.77h1.079v-1.081Z" transform="translate(-389.426 -178.224)" fill="#fea832"/>
    </g>
    <g id="Group_97849" data-name="Group 97849" transform="translate(2.157 58.709)">
      <path id="Path_215349" data-name="Path 215349" d="M33.2,58.709V59.79h1.079v.77H33.2v1.081h-.772V60.56H31.347v-.77h1.081V58.709Z" transform="translate(-31.347 -58.709)" fill="#fea832"/>
    </g>
    <g id="Group_97850" data-name="Group 97850" transform="translate(0.5 59.967)">
      <path id="Path_215350" data-name="Path 215350" d="M.687,93.218c-1-1.737,2.134-5.425,7.006-8.237s9.633-3.685,10.636-1.95Q19.163,84.477,20,85.92c1,1.737-2.134,5.425-7.006,8.237S3.358,97.843,2.355,96.106L.687,93.218Z" transform="translate(-0.5 -82.139)" fill="#fe9923" fill-rule="evenodd"/>
    </g>
    <g id="Group_97851" data-name="Group 97851" transform="translate(0.5 59.967)">
      <path id="Path_215351" data-name="Path 215351" d="M7.693,84.981c4.872-2.813,9.633-3.685,10.636-1.95s-2.134,5.425-7.006,8.237S1.69,94.953.687,93.218s2.134-5.425,7.006-8.237Z" transform="translate(-0.5 -82.139)" fill="#fecd3d" fill-rule="evenodd"/>
    </g>
    <g id="Group_97852" data-name="Group 97852" transform="translate(2.424 61.568)">
      <path id="Path_215352" data-name="Path 215352" d="M42.438,114.659c3.876-2.237,7.451-3.3,7.985-2.376s-2.176,3.488-6.051,5.727-7.45,3.3-7.984,2.376S38.562,116.9,42.438,114.659Z" transform="translate(-36.321 -111.949)" fill="#fea832" fill-rule="evenodd"/>
    </g>
    <path id="Path_215353" data-name="Path 215353" d="M78.828,187.288l.808,1.4q-.391.206-.779.394l-.783-1.356Zm-6.848,2.468.7,1.209a9.424,9.424,0,0,0,.944-.106l-.887-1.539Zm3.173-.8.765,1.325q.41-.134.834-.294l-.845-1.465Zm5.647-2.809.808,1.4q.373-.234.729-.476l-.783-1.354Zm5.561-4.7-.755.437.889,1.537c.211-.259.4-.516.564-.764Zm-3.03,2.785.845,1.465q.351-.286.672-.575l-.764-1.323Z" transform="translate(-67.641 -116.151)" fill="#c85929"/>
  </g>
</svg>

export const DeleteIcon = ()=>
<svg width="15.416" height="17.54" viewBox="0 0 15.416 17.54">
  <g id="Group_97874" data-name="Group 97874" transform="translate(1.069 5.139)">
    <g id="Group_97873" data-name="Group 97873">
      <path id="Path_215368" data-name="Path 215368" d="M62.205,150l.91,10.988a1.55,1.55,0,0,0,1.536,1.414h8.387a1.55,1.55,0,0,0,1.536-1.414L75.483,150Zm4.069,10.346a.514.514,0,0,1-.512-.482l-.514-8.29a.514.514,0,0,1,.481-.545.506.506,0,0,1,.545.481l.514,8.29A.514.514,0,0,1,66.274,160.346Zm3.084-.514a.514.514,0,1,1-1.028,0v-8.29a.514.514,0,0,1,1.028,0Zm3.082-8.258-.514,8.29A.514.514,0,0,1,70.9,159.8l.514-8.29a.522.522,0,0,1,.545-.481A.514.514,0,0,1,72.44,151.574Z" transform="translate(-62.205 -150)" fill="#b4bad3"/>
    </g>
  </g>
  <g id="Group_97876" data-name="Group 97876">
    <g id="Group_97875" data-name="Group 97875">
      <path id="Path_215369" data-name="Path 215369" d="M45.388,2.055H42.305V1.542A1.543,1.543,0,0,0,40.763,0H36.653a1.543,1.543,0,0,0-1.542,1.542v.514H32.028a1.028,1.028,0,1,0,0,2.055H45.388a1.028,1.028,0,1,0,0-2.055Zm-4.111,0H36.139V1.542a.514.514,0,0,1,.514-.514h4.111a.514.514,0,0,1,.514.514Z" transform="translate(-31)" fill="#b4bad3"/>
    </g>
  </g>
</svg>
export const ClockIcon = ()=>
<svg width="17.698" height="17.698" viewBox="0 0 17.698 17.698">
  <path id="clock_4_" data-name="clock (4)" d="M8.849,0A8.849,8.849,0,1,0,17.7,8.849,8.859,8.859,0,0,0,8.849,0Zm4.209,13.426a.737.737,0,0,1-1.043,0L8.328,9.739a.734.734,0,0,1-.216-.521V4.425a.737.737,0,0,1,1.475,0V8.913l3.471,3.471A.737.737,0,0,1,13.058,13.426Zm0,0" fill="#00057d"/>
</svg>

export const MuteIcon = () =>
<svg id="Component_3_1" data-name="Component 3 – 1" width="50px" height="50px" viewBox="0 0 52 52">
  <g id="mute_1_" data-name="mute (1)" transform="translate(0 0.018)">
    <circle id="Ellipse_52" data-name="Ellipse 52" cx="26" cy="26" r="26" transform="translate(0 -0.018)" fill="#ea2525"/>
    <path id="Path_78582" data-name="Path 78582" d="M147.432,129.031a7.761,7.761,0,0,1-5.253-2.051l-1.887,1.887a10.44,10.44,0,0,0,5.807,2.746V136.5h2.667v-4.883a10.5,10.5,0,0,0,9.128-10.376V119.9h-2.667v1.334a7.82,7.82,0,0,1-7.794,7.794Zm5.2-12.508v4.613a5.2,5.2,0,0,1-8.571,3.958l8.571-8.571Zm-14.847,8.759a10.371,10.371,0,0,1-.818-4.045V119.9h2.667v1.334a7.733,7.733,0,0,0,.248,1.947l2.342-2.342v-8.877a5.2,5.2,0,0,1,10.228-1.35L157.072,106l1.886,1.886-2.364,2.364-3.958,3.958L143,123.848l-1.905,1.905-1.905,1.905-1.555,1.555-1.552,1.552-1.886-1.886Z" transform="translate(-120.552 -95.501)" fill="#fff" fill-rule="evenodd"/>
  </g>
  <g id="Group_72732" data-name="Group 72732" transform="translate(0 1.227)">
    <ellipse id="Ellipse_53" data-name="Ellipse 53" cx="26" cy="25.5" rx="26" ry="25.5" transform="translate(0 -0.227)" fill="#ea2525"/>
    <path id="microphone" d="M15.229,21.322a5.69,5.69,0,0,1-5.683-5.683V7.683a5.683,5.683,0,0,1,11.366,0v7.956A5.69,5.69,0,0,1,15.229,21.322Zm10.229-5.683a1.137,1.137,0,0,0-2.273,0,7.956,7.956,0,1,1-15.912,0,1.137,1.137,0,1,0-2.273,0,10.229,10.229,0,0,0,20.458,0Zm-9.093,12.5v-3.41a1.137,1.137,0,0,0-2.273,0v3.41a1.137,1.137,0,0,0,2.273,0Z" transform="translate(10.49 9.421)" fill="#fff"/>
  </g>
</svg>



export const MuteMiceIcon = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 21.558 24.023">
      <path id="microphone_mute" d="M697.029,1388.88a5.858,5.858,0,0,0,5.851-5.851v-3.312l4.656-4.656a.924.924,0,1,0-1.307-1.307l-19.71,19.71a.924.924,0,0,0,1.306,1.307l3.845-3.845a9.5,9.5,0,0,0,4.435,1.6v1.9h-4a.924.924,0,1,0,0,1.848h9.855a.924.924,0,1,0,0-1.848h-4v-1.895a9.559,9.559,0,0,0,8.623-9.5.924.924,0,0,0-1.848,0,7.666,7.666,0,0,1-11.7,6.547l1.355-1.355A5.813,5.813,0,0,0,697.029,1388.88Zm4-5.851a4.008,4.008,0,0,1-4,4,3.942,3.942,0,0,1-1.254-.211l5.258-5.257Zm-9.712,1.3a6.331,6.331,0,0,1-.143-1.3V1378.1a5.845,5.845,0,0,1,10.651-3.334.924.924,0,0,1-1.519,1.051,4,4,0,0,0-7.284,2.284v4.927a4.6,4.6,0,0,0,.1.922.924.924,0,0,1-.718,1.091.889.889,0,0,1-.188.02.92.92,0,0,1-.9-.737Zm-2.773,3.1a9.541,9.541,0,0,1-1.066-4.393.924.924,0,0,1,1.848,0,7.692,7.692,0,0,0,.862,3.541.923.923,0,0,1-.393,1.245.911.911,0,0,1-.426.1.922.922,0,0,1-.826-.5Z" transform="translate(-686.249 -1372.249)" fill="#999"/>
    </svg>


export const EditIcon = () =>
<svg id="edit" xmlns="http://www.w3.org/2000/svg" width="27.802" height="27.801" viewBox="0 0 27.802 27.801">
  <path id="Path_64" data-name="Path 64" d="M22.01,95.084a1.158,1.158,0,0,0-1.158,1.158v9.267a1.16,1.16,0,0,1-1.158,1.158H3.475a1.159,1.159,0,0,1-1.158-1.158V89.292a1.159,1.159,0,0,1,1.158-1.158h9.267a1.158,1.158,0,0,0,0-2.317H3.475A3.479,3.479,0,0,0,0,89.292v16.218a3.479,3.479,0,0,0,3.475,3.475H19.693a3.479,3.479,0,0,0,3.475-3.475V96.242A1.157,1.157,0,0,0,22.01,95.084Zm0,0" transform="translate(0 -81.184)" fill="#030a7e"/>
  <path id="Path_65" data-name="Path 65" d="M182.8,77.427a.586.586,0,0,0-.159.3l-.819,4.1a.579.579,0,0,0,.569.693.547.547,0,0,0,.113-.011l4.1-.819a.577.577,0,0,0,.3-.159l9.165-9.165-4.095-4.095Zm0,0" transform="translate(-171.936 -64.582)" fill="#030a7e"/>
  <path id="Path_66" data-name="Path 66" d="M397.143,1.348a2.9,2.9,0,0,0-4.095,0l-1.6,1.6,4.1,4.095,1.6-1.6a2.9,2.9,0,0,0,0-4.095Zm0,0" transform="translate(-370.19 -0.501)" fill="#030a7e"/>
</svg>
export const WinnerIcon = () =>
<svg id="Group_98685" data-name="Group 98685" xmlns="http://www.w3.org/2000/svg" width="14.275" height="14.275" viewBox="0 0 14.275 14.275">
  <g id="Group_98684" data-name="Group 98684">
    <path id="Path_215453" data-name="Path 215453" d="M13.561.714H11.42V0H2.855V.714H.714A.674.674,0,0,0,0,1.427V3.14A3.238,3.238,0,0,0,2.855,6.352v.071A4.293,4.293,0,0,0,6.21,10.635l-.5,1.5H4.068a.686.686,0,0,0-.642.5l-.571,1.642H11.42l-.571-1.642a.686.686,0,0,0-.642-.5H8.565l-.5-1.5A4.293,4.293,0,0,0,11.42,6.424V6.352A3.238,3.238,0,0,0,14.275,3.14V1.427A.674.674,0,0,0,13.561.714ZM2.855,4.925A1.906,1.906,0,0,1,1.427,3.14v-1H2.855Zm5.71,2.213L7.137,6.352,5.71,7.137,6.067,5.71,5,4.282H6.5l.642-1.427L7.78,4.282h1.5L8.208,5.71Zm4.282-4A1.842,1.842,0,0,1,11.42,4.925V2.141h1.427Z" fill="#f07721"/>
  </g>
</svg>


export const DownloadIcon = () =>
<svg  height="20" viewBox="0 0 512 20" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m427.41 453.4v51.6c0 3.86-3.13 7-7 7h-328.82c-3.87 0-7-3.14-7-7v-51.6c0-3.86 3.13-7 7-7h328.82c3.87 0 7 3.14 7 7zm8.57-226.56c-1.09-2.61-3.64-4.32-6.47-4.32h-88.92v-215.52c0-3.86-3.14-7-7.01-7h-155.16c-3.87 0-7.01 3.14-7.01 7v215.52h-88.92c-2.83 0-5.38 1.71-6.47 4.32-1.08 2.62-.48 5.63 1.52 7.64l173.51 173.5c1.31 1.32 3.09 2.06 4.95 2.06s3.64-.74 4.95-2.06l173.51-173.5c2-2.01 2.6-5.02 1.52-7.64z"/></svg>


export const EditIcon1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 24 24"
    fill="none"
    stroke="blue"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 20h9" />
    <path d="M16.5 3.5a2.121 2.121 0 1 1 3 3l-11 11-4 1 1-4 11-11z" />
  </svg>
);
