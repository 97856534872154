import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md';

function PrivacyPolicy() {
        const navigate = useNavigate();
        const goBack = () => { navigate(-1); };

        useEffect(() => {
                window.scrollTo(0, 0)
        }, [])


        return (
                <div className='privacy_policy_parent'>
                        <div className='header'>
                                <span onClick={goBack}><MdArrowBackIos /></span>
                                <Link to="/">  <img src='https://d2rqcufsarnep3.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
                        </div>
                        <div className='privacy_policy'>
                                <h4> Privacy Policy for Bidsboxx</h4>
                                Effective Date: [18-7-2024]
                                <p>1. Introduction </p>
                                <p>This Privacy Policy ("Policy") describes how Bidsboxx ("we," "us," or "our") collects, uses,
                                        discloses, and protects the personal information of users ("you" or "your") who visit or make
                                        purchases from our website, www.Bidsboxx.com (the "Site"). By accessing or using the Site, you
                                        agree to the terms of this Policy.</p>
                                <p>2. Information We Collect</p>
                                <div className='content'>
                                        <p>2.1 Personal Information</p>
                                        <div className='inner_content'>

                                                <p>We may collect various categories of personal information, including but not limited to:</p>

                                                <p><b>● Identifiers:</b>. Your name, email address, postal address, phone number, unique identifiers
                                                        such as account name and password.
                                                </p><p><b>● Financial Information:</b>. Payment card details, billing information necessary to process
                                                        transactions.
                                                </p><p><b>● Commercial Information:</b>. Records of products or services purchased, obtained, or
                                                        considered, and other purchasing or consuming histories.
                                                </p><p><b>● Internet or Other Electronic Network Activity Information:</b>. Browsing history, search
                                                        history, and information regarding your interaction with the Site.
                                                </p><p><b>● Geolocation Data:</b>. Physical location information.
                                                </p><p><b>● Audio, Electronic, Visual, or Similar Information:</b>. Such as customer support call
                                                        recordings.
                                                </p><p><b>● Professional or Employment-Related Information:</b>. For employment applications,
                                                        such as your resume and contact details.</p>

                                        </div></div>

                                <div className='content'>
                                        <p>2.2 Non-Personal Information</p>
                                        <div className='inner_content'>


                                                <p>We also collect non-personal information that does not, on its own, permit direct association with
                                                        any specific individual. This includes:</p>


                                                <p><b>● Device Information:</b> Information about your web browser, IP address, time zone, and
                                                        some of the cookies installed on your device.
                                                </p><p><b>● Usage Data:</b> Information about how you use our website, products, and services.
                                                </p>

                                        </div></div>
                                <p>3. How We Collect Information</p>
                                <div className='content'>

                                        We collect information from you in the following ways:
                                        <p><b>● Direct Collection:</b> When you provide information directly to us, such as when you
                                                create an account, make a purchase, fill out forms, or contact customer service.
                                        </p><p><b>● Automated Collection:</b> Through the use of cookies, web beacons, and other tracking
                                                technologies, we automatically collect information about your device and your interaction
                                                with our Site.
                                        </p><p><b>● Third-Party Sources:</b> We may receive information about you from other sources,
                                                including third-party service providers, social media platforms, and public databases.
                                        </p></div>


                                <p>4. How We Use Your Information
                                        We use your personal information for various purposes, including:
                                </p>
                                <div className='content'>
                                        <p><b>●Order Processing and Fulfillment:</b> To process and fulfill your orders, manage your
                                                account, and provide customer support.
                                        </p><p><b>●Communication:</b> To communicate with you about your orders, respond to your inquiries,
                                                and send you promotional materials (with your consent, where required by law).
                                        </p><p><b>●Personalization:</b> To customize your experience on the Site and to provide personalized
                                                content and recommendations.
                                        </p><p><b>●Security and Fraud Prevention:</b> To protect the security and integrity of our Site,
                                                systems, and data, and to prevent fraud and other illegal activities.
                                                Legal Compliance: To comply with applicable laws, regulations, and legal processes,
                                                and to respond to lawful requests, court orders, and legal proceedings.
                                        </p><p><b>●Marketing and Advertising:</b> To conduct marketing and promotional activities, including
                                                delivering targeted advertising based on your interests and interactions with our Site.
                                        </p>

                                </div>


                                <p>5. Sharing Your Information
                                        We do not sell your personal information. We may share your personal information with third
                                        parties in the following circumstances:
                                </p>
                                <div className='content'>
                                        <p><b>● Service Providers:</b> With third-party service providers that perform services on our
                                                behalf, such as payment processing, order fulfillment, and customer service, provided
                                                they agree to use such information only for the purpose of providing the services to us.

                                        </p><p><b>● Business Transfers:</b> In connection with a merger, sale of company assets, financing, or
                                                acquisition of all or a portion of our business by another company.
                                        </p><p><b>● Legal Requirements:</b> If required to do so by law or in the good faith belief that such
                                                action is necessary to comply with legal obligations, protect and defend our rights or
                                                property, or prevent fraud and other illegal activities.
                                        </p><p><b>● Protection of Rights:</b> When we believe disclosure is necessary or appropriate to
                                                protect the rights, property, or safety of Bidsboxx, our customers, or others.</p>


                                </div>
                                <p>6. Security of Your Information
                                        We implement reasonable administrative, technical, and physical security measures to protect
                                        your personal information from unauthorized access, use, alteration, and disclosure. These
                                        measures include, but are not limited to:
                                </p>
                                <div className='content'>
                                        <p><b>● Encryption:</b> Using SSL encryption to protect sensitive information transmitted online.
                                        </p><p><b>● Access Controls:</b> Limiting access to personal information to employees and contractors
                                                who need it to perform their job duties.
                                        </p><p><b>● Regular Audits:</b> Conducting regular audits and assessments of our data security
                                                practices and systems.</p>
                                        <p>However, no method of transmission over the internet or electronic storage is completely
                                                secure, and we cannot guarantee its absolute security. </p>

                                </div>



                                <p>7. Cookies and Similar Technologies
                                        We use cookies and similar tracking technologies to enhance your experience on our Site.
                                        Cookies are small text files placed on your device that help us remember your preferences and
                                        track your usage of the Site. The types of cookies we use include:</p>
                                <div className='content'>
                                        <p><b>● Essential Cookies:</b> Necessary for the operation of the Site, enabling you to navigate
                                                and use its features.
                                        </p><p><b>● Performance Cookies:</b> Collect information about how visitors use the Site, helping us
                                                improve its functionality.
                                        </p><p><b>● Functional Cookies:</b> Remember your preferences and personalize your experience.
                                        </p><p><b>● Advertising Cookies:</b> Track your browsing habits to deliver targeted advertising based
                                                on your interests.</p>
                                        <p>You can manage your cookie preferences through your browser settings. However, disabling
                                                cookies may affect the functionality of the Site.</p>


                                </div>



                                <p>8. Third-Party Links
                                        Our Site may contain links to third-party websites. We are not responsible for the privacy
                                        practices or the content of these third-party sites. We encourage you to review the privacy
                                        policies of any third-party sites you visit.</p>

                                <p> 9. Your Rights and Choices
                                        You have certain rights and choices regarding your personal information, including: </p>
                                <div className='content'>
                                        <p><b>● Access and Correction:</b> You may request access to the personal information we hold
                                                about you and ask us to correct any inaccuracies.
                                        </p><p><b>● Deletion:</b> You may request that we delete your personal information, subject to certain
                                                exceptions.
                                        </p><p><b>● Opt-Out of Marketing Communications:</b> You may opt-out of receiving promotional
                                                emails from us by following the unsubscribe instructions in those emails. Please note
                                                that even if you opt-out, we may still send you non-promotional communications, such as
                                                those about your account or orders.
                                        </p><p><b>● Cookies and Tracking Technologies:</b> You can manage your cookie preferences
                                                through your browser settings.</p>

                                        <p>To exercise these rights, please contact us using the information provided in the "Contact Us" section below.</p></div>

                                <p>10. Children's Privacy
                                        Our Site is not intended for children under the age of 13. We do not knowingly collect personal
                                        information from children under 13. If we become aware that we have inadvertently received
                                        personal information from a child under 13, we will delete such information from our records.</p>
                                <p>11. International Data Transfers
                                        If you are accessing our Site from outside the United States, please be aware that your
                                        information may be transferred to, stored, and processed in the United States and other
                                        countries. The data protection laws of these countries may differ from those in your jurisdiction.
                                        By using our Site, you consent to the transfer of your information to the United States and other
                                        countries as described in this Policy.</p>

                                <p>12. Changes to This Privacy Policy
                                        We may update this Privacy Policy from time to time to reflect changes in our practices or for
                                        other operational, legal, or regulatory reasons. When we update this Policy, we will revise the
                                        "Effective Date" at the top of this page. We encourage you to review this Policy periodically to
                                        stay informed about how we are protecting your information. If we make material changes to this
                                        Policy, we will notify you by prominently posting a notice on our Site or by sending you a
                                        notification.</p>
                                <p>13. Contact Us If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                                <p><Link to="/">bidsboxx.com</Link></p>
                                <p>Email: bidsboxx@gmail.com</p>
                                <p>Phone: +91 7419741977</p>

                        </div>
                </div>
        )
}

export default PrivacyPolicy