import React, { useState, useEffect } from 'react';
import '../StyleComponent/live.scss';
import Viewer from './Viewer';
import LiveComment from './LiveComment'
import VideoSection from './VideoSection'
import { Spinner } from 'react-bootstrap';
import animationData from '../assets/Animation1.json';
import animationProper from '../assets/animation2.json';
import socket from '../Socket/io';
import Lottie from 'react-lottie';
import { useNavigate, useLocation } from 'react-router';
import Header from '../CommonComponent/Header';
import { apiService } from '../Services/ApiServices';
import { notify } from '../CommonComponent/Toaster';
import { confirmAlert } from 'react-confirm-alert';
import useWindowSize from './useWindowSize';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: animationProper,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function Live(props) {
  const { width } = useWindowSize();
  const token = localStorage.getItem('token');
  const { state } = useLocation();
  const navigate = useNavigate();
  const passId = state;
  console.log("state_______________",state)
  const [modalShow, setModalShow] = useState(false);
  const [animateLoader, setAnimateLoader] = useState(true)
  const [wonAnimateLoader, setWonAnimateLoader] = useState(false)
  const [addMeetingData, setMeetingData] = useState();
  const [getProductCount, setProductCount] = useState(false);
  const [productCountNumer, setProductCountNumer] = useState();
  const [unSoldProductCount, setUnSoldProductCount] = useState();
  const [showProductlistmobile, setShowProductlistmobile] = useState();
  const [getWinner, setWinner] = useState({});
  const showModal = (item) => { setModalShow(item) };
  const handleClose = (item) => { setModalShow(false) };
  const [isThreeMinutesWarningShown, setThreeMinutesWarningShown] = useState(false);
  const [isOneMinuteWarningShown, setOneMinuteWarningShown] = useState(false);
  const [hasAuctionEnded, setHasAuctionEnded] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  function endMeetingSocket(auctionId) {
    try {
      console.log("end_auction",localStorage.getItem("room_id"))
      socket.emit('end_auction', { "auction_id": auctionId, "room_id": localStorage.getItem("room_id") });
      const handleStatus = (data) => {
        let errorMessage = data?.error?.message?.detail;
        localStorage.removeItem("room_id");
        localStorage.removeItem("auctionId");
      };
      socket.on('status', handleStatus);
    }
    catch (e) {
      console.error("chime meeting end_________________", e);
    }
  }

  const stopMeetingSessionAwsChime = () => {
    if (addMeetingData) {
      try {
        console.log("stopMeetingSessionAwsChime true", true)
        addMeetingData.audioVideo.stopLocalVideoTile();
        addMeetingData.audioVideo.stopVideoInput();
        addMeetingData.audioVideo.stopAudioInput()
        addMeetingData.audioVideo.chooseAudioOutput(null);
        addMeetingData.audioVideo.startVideoInput(null);
        addMeetingData.audioVideo.startVideoInput(null);
        addMeetingData.audioVideo.stop();
        localStorage.removeItem("isMeeting");
        stopYoutubeUrl(passId?.id);
        localStorage.setItem("socketload", false);
        localStorage.removeItem("selectedProduct_id");
        localStorage.removeItem("_expiredTime");
        navigate("/auction")

      } catch (error) {
        console.log("stopMeetingSessionAwsChime false", false)
        navigate("/auction")
        console.error('Error stopping meeting session:', error);
      }
    }
  }

  const stopYoutubeUrl = async (id) => {
    let params = { auction_id: id };
    let response = await apiService.stopYoutubeUrl(params, token);
    if (response?.data?.status_code == 1) {
    }
  }

  const getDataFromVideoSection = (data) => {
    setMeetingData(data)

  }

  const getProductListStaus = (data, dLength) => {
    if (data) {
      setProductCount(data);
      setProductCountNumer(dLength);
    }
  }

  const getProductCountData = (unsold_product_count) => {
    setUnSoldProductCount(unsold_product_count);
  }


  function endMeetingSocketCatch(auctionId) {
    try {
      console.log("end_auction catch",auctionId)
      socket.emit('end_auction', { "auction_id": auctionId, "room_id": localStorage.getItem("room_id") });
      const handleStatus = (data) => {
        let errorMessage = data?.error?.message?.detail;
        console.log("errorMessage______________________",errorMessage)
        localStorage.removeItem("room_id");
        localStorage.removeItem("auctionId");
      };
      socket.on('status', handleStatus);
      navigate("/auction")
    }
    catch (e) {
      navigate("/auction")
      console.error("chime meeting end_________________", e);
    }
  }

  const endMeetingSession = () => {
    try {
      console.log("endMeetingSession____________true",true, state)
      stopMeetingSessionAwsChime();
      endMeetingSocket(localStorage.getItem("auctionId"))
      localStorage.setItem("socketload", false);
      localStorage.removeItem("selectedProduct_id");
      localStorage.removeItem("_expiredTime");
      localStorage.removeItem("isMeeting");
      localStorage.removeItem("room_id");
      localStorage.removeItem("auctionId");
      addMeetingData.audioVideo.stop();
      navigate("/auction")
    } catch (error) {
      console.log("endMeetingSession____________false",false)
      // endMeetingSocket(localStorage.getItem("auctionId"))
      endMeetingSocketCatch(state?.id)
      stopMeetingSessionAwsChime();
      
    }
  }

  const hideProductModalPopup = () => {
    confirmAlert({
      title: 'BidsBoxx ',
      message: 'Auction has been ended',
      buttons: [
        {
          label: 'Ok',
          onClick: () => endMeetingSession()
        }
      ]
    });
  }

  useEffect(() => {
    const checkEndTime = () => {
      let currentTime = Date.now();
      let endTime = state?.item?.end_time * 1000;
      let threeMinutesBeforeEnd = endTime - (3 * 60 * 1000);
      let oneMinuteBeforeEnd = endTime - (60 * 1000);
      if (currentTime >= threeMinutesBeforeEnd && currentTime < oneMinuteBeforeEnd && localStorage.getItem('auctionId')) {
        if (!isThreeMinutesWarningShown) {
          //Auction about to end in 3 minutes.
          notify("err", "Auction about to end in 3 minutes.");
          setThreeMinutesWarningShown(true);
        }
      }
      if (currentTime >= oneMinuteBeforeEnd && currentTime < endTime && localStorage.getItem('auctionId')) {
        if (!isOneMinuteWarningShown) {
          //Auction will end in 1 minute. Showing the winner.
          if (getWinner){
            // notify("success", getWinner?.response_data?.winner_user?.name + " Successfully won the auction");
          }
          setOneMinuteWarningShown(true);
        }
      }

      if (currentTime >= endTime) {
        if (!hasAuctionEnded) {
          hideProductModalPopup();
          endMeetingSession();
          setHasAuctionEnded(true);
        }
      }
    };

    checkEndTime();
    const intervalId = setInterval(checkEndTime, 1000); // Check every second
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [state?.item?.end_time, isThreeMinutesWarningShown, isOneMinuteWarningShown, hasAuctionEnded]);

  const getWinnerData = (data) => {
    if (data) {
      setWinner(data)
    }
  }

  const showProductList = (value) => {
    setShowProductlistmobile(value)
  }

  useEffect(() => {
    window.addEventListener("popstate", e => {
      navigate(1);
      localStorage.setItem('isMeeting', true);
      localStorage.setItem('auctionId', passId?.id);
    });
  })

  useEffect(() => {
    var alertMessage = "Are you sure you want to leave? Changes you made may not be saved.";
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.stopPropagation();
      event.returnValue = alertMessage; // This is necessary for some browsers
      return alertMessage; // For other browsers
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  let j = 0;
  const [status, setStatus] = useState(true);
  const [stateCount, setStateCount] = useState(j);

  useEffect(() => {
    function changeStatus() {
      const online = navigator.onLine;
      setStatus(online);
      if (online == false)
        setIsLoader(true)

      if (online) {
        setStateCount(prevCount => prevCount + 1);
        setIsLoader(true)
      }
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);


  useEffect(() => {
    if (stateCount == 1)
      window.location.reload()
  }, [stateCount]);

  return (
    <>
      {
        (isLoader == true) ? <div className="_live_loader"><span className='me-3'><Spinner /></span> You Lost Your network connection. Trying to reconnect...</div>
          :
          <>
            <div className='show_only_desktop'>
              <Header stopMeetingSessionAwsChime={stopMeetingSessionAwsChime} endMeetingSocket={endMeetingSocket} onlylogo={true} />
              <div className={`live_section ${modalShow ? 'cm_modal_open' : ''}`}>
                {
                  (width > 991) &&
                  <VideoSection showModal={showModal} handleClose={handleClose} auctionId={state?.id}
                    getDataFromVideoSection={getDataFromVideoSection}
                    getProductCount={getProductCount}
                    productCountNumer={productCountNumer}
                    unSoldProductCount={unSoldProductCount}
                    getProductCountData={getProductCountData}
                    getProductListStaus={getProductListStaus}
                    getWinnerData={getWinnerData}
                    showProductlistmobile={showProductlistmobile}
                  />
                }
                <div className='_right_section'>
                  <Viewer />
                  <LiveComment showProductList={showProductList} />
                </div>
                {modalShow &&
                  <div className={`cm_modal ${!animateLoader ? '__center_text_icon' : ''}`}>
                    {animateLoader && <Lottie options={defaultOptions}
                      height={300}
                      width={300}
                    />
                    }{wonAnimateLoader &&
                      <div className='_winner_animation'>
                        <Lottie options={defaultOptions1}
                          height={300}
                          width={300}
                        />
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
            <div className='show_only_mobile _live_mobile'>
              <div className={`live_section ${modalShow ? 'cm_modal_open' : ''}`}>
                <div className='top_bar show_mobile'>
                  <button className="btn   end_action lower_case common_button avenir_heavy" onClick={endMeetingSession} >End Auction</button>
                </div>
                <Viewer />
                {
                  (width <= 991) &&
                  <VideoSection showModal={showModal} handleClose={handleClose} auctionId={state?.id}
                    getDataFromVideoSection={getDataFromVideoSection}
                    getProductCount={getProductCount}
                    productCountNumer={productCountNumer}
                    unSoldProductCount={unSoldProductCount}
                    getProductCountData={getProductCountData}
                    getProductListStaus={getProductListStaus}
                    getWinnerData={getWinnerData}
                    showProductlistmobile={showProductlistmobile}
                    showProductList={showProductList}
                  />
                }
                <LiveComment showProductList={showProductList} />
              </div>
            </div>
          </>
      }
    </>
  )
}

export default Live;