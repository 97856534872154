import React, { useRef, useState, useReducer, useEffect } from 'react';
import BlankPage from '../../CommonComponent/BlankPage';
import { ProductImage } from '../../CommonComponent/ImageConstants';
import Button from '../../CommonComponent/Button'
import '../../StyleComponent/product.scss'
import SearchBox from '../../CommonComponent/SearchBox'
import FilterBox from '../../CommonComponent/FilterBox'
import AddProductModal from './AddProductModal';
import ProductDetailsModal from './ProductDetailsModal';
import { LinkedImage } from '../../CommonComponent/ImageConstants';
import ScrollComponent from '../../CommonComponent/ScrollComponent';
import { apiService } from '../../Services/ApiServices';
import BaseLoader from '../../CommonComponent/BaseLoader';
import 'react-loading-skeleton/dist/skeleton.css';
import { RxCross2 } from "react-icons/rx";
import { confirmAlert } from 'react-confirm-alert';
import { ProductSkeleton } from '../../CommonComponent/Skeleton';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';

function Product(props) {
  const logout = useLogout();
  const token = localStorage.getItem('token');
  const [inputLoader, setInputLoader] = useState(false);
  const [productId, setProductId] = useState('')
  const [getCategory, setCategory] = useState([]);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      list: {},
      isResponse: '',
      searchType: '',
      inactive: '',
      bP: '',
      totalCount: '',
      activePage: 1,
      dir: '',
      sortValue: '',
      type: '',
      categoryListData: {}
    }
  );


  const [limit, setLimit] = useState(2000); // Number of items per page
const [offset, setOffset] = useState(0); // Offset for API
const [currentPage, setCurrentPage] = useState(1); // Current page
const [isFetching, setIsFetching] = useState(false); 

  const [getSearchCategory, setSearchCategory] = useState([]);

  const [searchProduct, setSearchProduct] = useState('')
  const [imageLinked, setImageLinked] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowProduct, setModalShowProduct] = useState(false);
  const containerRef = useRef(null);
  const openAddProductPopup = (item) => {
    setModalShow(item)
  }

  const [isFetched, setIsFetched] = useState(false); // Flag for tracking

  const hideProductModalPopup = () => {
    confirmAlert({
      title: 'Confirm ',
      message: 'Are you sure you want to discard your changes?',
      buttons: [
        {
          label: 'YES',
          onClick: () => setModalShow(false)
        },
        {
          label: 'NO',
        }
      ]
    });
  }

  const cleanCategories = (categories) => {
    if (!Array.isArray(categories)) {
      console.warn("Invalid input: categories should be an array");
      return [];
    }

    return categories.filter((cat) => typeof cat === "number");
  };

  const productList = (category) => {
    let req = { limit,
      offset};
    setInputLoader(true);
    setUserInput({ list: [], totalCount: 0, isLoader: true });
    if (category) {
      const cleanedCategory = cleanCategories(category);
      req.categories = cleanedCategory; // Assign only valid integers
      setCategory(category)
      setSearchCategory(req.categories)
    }
    apiService.productList(req, token).then(res => {
      if (res?.data?.status_code == 1) {
        setInputLoader(false);
        setIsFetched(true)
        setUserInput({ list: res?.data?.response_data.product_list, totalCount: res?.data.response_data.count, isLoader: false })
      }
      else {
        notify("err", res?.data?.error?.message?.error);
        if (res?.data?.status_code == 2)
          logout();

        if (res?.data?.status_code == 5)
          logout();
        if (res?.data?.status_code == 4)
          logout();
      }

    })
  }

  const categoryList = () => {
    apiService.categoryList().then(res => {
      console.log("res_____________________")
      if (res.data?.status_code == 1) {
        setUserInput({ categoryListData: res?.data?.response_data.categories });
        setCategory(res?.data?.response_data.categories)
      }
      else {
        setUserInput({ categoryListData: [] });
      }
    })
  }

  const onlyClose = (state) => {
    setModalShow(state)
  }

  useEffect(() => {
    // if (!isFetched) {
    productList()
    categoryList()
    // }

  }, [])

  const openProductDetailPopup = (id) => {
    setProductId(id);
    setModalShowProduct(true)
  }

  const handleSearchChange = e => {
    setSearchProduct(e?.target?.value);
    if (!e?.target?.value) {
      setSearchProduct('');
      productList()
    }
  }

  useEffect(() => {
    if ((searchProduct.length > 2) || (searchProduct.length == 0)) {

      console.log("setCategory________________", getSearchCategory, getSearchCategory.length)
      const fetchData = async () => {
        setInputLoader(true)
        try {

          let req = { search: searchProduct,  limit,
            offset };
          if (getSearchCategory.length)
            req.categories = getSearchCategory

          if (searchProduct.length == 0)
            req = {};
          const token = localStorage.getItem('token');
          const response = await apiService.productList(req, token).then(async res => {
            if (res?.data?.status_code == 1) {
              setInputLoader(false);
              setUserInput({ list: res?.data?.response_data.product_list, totalCount: res?.data.response_data.count, isLoader: false })
            }
            else {
              setInputLoader(false);
              setUserInput({
                loading: false
              })
            }
          })

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const timeoutId = setTimeout(() => {
        if (searchProduct.trim() !== '') {
          fetchData();
        }
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchProduct]);

  const unselectCateg = e => {
    productList(getCategory.filter(item => item !== e))

  }

  const categoryListSpan = [
    ...getCategory,
    { id: 0, name: "Product not scheduled for auction" },
  ].filter((category) => category.name);;

  const spanElements = categoryListSpan.map((category) => (
    <span key={category.id}>
      {category.name}
      <i onClick={() => unselectCateg(category.id)}>
        <RxCross2 />
      </i>
    </span>
  ));

  // const selectedSpans = spanElements.filter((span, index) =>{ 
  //   console.log("spanspanspanspanspan",span,index)
  //   categoryListSpan.includes(index + 1)

  // });

  const filteredCategories = categoryListSpan.filter((category) => getSearchCategory.includes(category.id));

  const selectedSpans = filteredCategories.map((category) => (
    <span key={category.id}>
      {category.name}
      <i onClick={() => unselectCateg(category.id)}>
        <RxCross2 />
      </i>
    </span>
  ));

  const data = [
    { id: -1, name: "Default showing all category products" }
  ];

  const selectedSpansAll = data.map((item) => (
    <span key={item.id}>
      {item.name}
    </span>
  ));


  console.log("selectedSpansselectedSpansselectedSpans", getCategory, selectedSpans, getSearchCategory)

  return (
    <>
      {(userInput.isLoader == true && userInput.totalCount > 0) ? <ProductSkeleton />
        : (userInput.isLoader == true && userInput.totalCount == 0) ? <BaseLoader />
          :
          <div className='dashboard_inner position_relative'>
            <div className='_top_bar mt-3 mb-5 justify-content-between'>
              <SearchBox placeholderText={"Search Products"}
                value={searchProduct}
                name="search"
                handleSearchChange={handleSearchChange} />
              <FilterBox productList={productList} getCategory={getCategory} selectedSpans={selectedSpans}/>
            </div>
            {
              (selectedSpans.length == 0)
                ?
                <div className='filtered_container'>{selectedSpansAll}</div>
                :
                <div className='filtered_container'>{selectedSpans} </div>

            }
            {
              (userInput.isLoader == true) ?
                <ProductSkeleton /> :
                <>
                  {userInput.totalCount == 0 ?
                    <>
                      {
                        ((selectedSpans.length > 0) || (searchProduct.length > 0)) ?
                          <div style={{ 'marginTop': '-120px' }}>
                            <BlankPage
                              text1={"Oops, there are no products found!"}
                              // text2={"There is no product available from selected category"}
                              btnName={"ADD PRODUCT"}
                              ClickFunction={openAddProductPopup}
                              link="/" />
                          </div>
                          :
                          <div style={{ 'marginTop': '-95px' }}>
                            <BlankPage
                              text1={"You haven't added any product yet"}
                              text2={"Please add a product"}
                              btnName={"ADD PRODUCT"}
                              ClickFunction={openAddProductPopup}
                              link="/" />
                          </div>
                      }
                    </>
                    :
                    <>
                      <div className=''>
                        <div className='product_container' ref={containerRef}>
                          <ScrollComponent containerRef={containerRef} authLength={userInput.totalCount} elementWidth={255}>
                            {userInput.list.map(item =>
                            (
                              <div className='product_box' key={item.id} onClick={() => openProductDetailPopup(item.id)}>
                                <div className='_image'>
                                  {item.is_linked && imageLinked && <span className='_icon'><img alt="icon" src={LinkedImage} /></span>}
                                  {item.image ? <img src={item.image} alt="product" /> : <img src={ProductImage} />}
                                </div>
                                <div className='_text'>
                                  <h6 className='mb-1'>{item.name}</h6>
                                  {/* <p className='_size'>Size - {item.size}</p> */}
                                  <p className='_price '><del className='me-3'>₹{item.original_mrp}</del><span>₹{item.auction_start_price}</span></p>
                                </div>
                              </div>
                            )
                            )}
                          </ScrollComponent>
                        </div>
                        <br /><br />
                      </div>
                      <div className='text-center mt-5'>
                        <Button name='ADD PRODUCT' className="lower_case" onClick={openAddProductPopup} />
                      </div>
                    </>
                  }
                </>
            }

            {modalShow && <AddProductModal
              show={modalShow}
              onHide={hideProductModalPopup}
              onlyClose={onlyClose}
              backdrop="static"
              updatedProductList={productList}
              categories={userInput.categoryListData}
            />}

            {modalShowProduct && <ProductDetailsModal
              show={modalShowProduct}
              onHide={() => setModalShowProduct(false)}
              onHide1={() => setModalShowProduct(false)}
              productId={productId}
              updatedProductList={productList}
              categories={userInput.categoryListData}
            />}
          </div>
      }
    </>
  )
}

export default Product
