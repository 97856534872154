import React, { useRef, useState, useCallback, useReducer, useEffect } from 'react';
import Button from '../../CommonComponent/Button';
import Modal from 'react-bootstrap/Modal';
import InlineError from '../../CommonComponent/inlineError';
import '../../StyleComponent/AuthStyle.css'
import Input from '../../CommonComponent/Input';
import { apiService } from '../../Services/ApiServices';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from "../../CommonComponent/Helper";
import BtnLoader from '../../CommonComponent/BtnLoader';
import { IoCloseCircle } from "react-icons/io5";
import { AddproductImg } from '../../CommonComponent/Skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { checkFileSize } from '../../CommonComponent/Validation';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import { EditIcon } from '../../Svg';
import { FaPencil } from 'react-icons/fa6';

function AddProductModal(props) {
  const logout = useLogout();
  const [btnLoader, setBtnLoader] = useState(false);
  const [newUrl, setNewUrl] = useState([]);
  const [cImage, setCImage] = useState('');
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const inputRef = useRef();
  const token = localStorage.getItem('token');
  const [document, setDocument] = useState([]);
  const [image, setImage] = useState([]);
  const [productName, setProductName] = useState('');
  const [productDetail, setProductDetail] = useState('');
  const [category, setCategory] = useState('');
  const [condition, setCondition] = useState('');
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [fabric, setFabric] = useState('');
  const [mrp, setMrp] = useState('');
  const [startPrice, setStartPrice] = useState('');
  const [bidAmountBoost, setBidAmountBoost] = useState('');
  const [isLinked, setIsLinked] = useState();
  const [isAllAdded, setisAllAdded] = useState(false);
  const [inputLoader, setInputLoader] = useState(false);
  const [getAuctionList, setAuctionList] = useState({});
  const [auctionId, setAuctionId] = useState('');
  const [checkImageLoader, setCheckImageLoader] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  // const cropInitialState = {
  //   aspect: 16 / 15,
  //   width: 100,
  // }
  // const [crop, setCrop] = useState({
  //   aspect: 16 / 15,
  //   width: 100,
  // });



  // const cropInitialState = {
  //   unit: '%', // Can be 'px' or '%'
  //   width: 100,
  //   aspect: 9 / 16,
  // }

  // const [crop, setCrop] = useState(cropInitialState);


  const cropInitialState = { aspect: 16 / 16, width: 100 }

  const [crop, setCrop] = useState({ aspect: 16 / 16, width: 100 ,height:100});

  const onSelectFile = async e => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("ssss_target_file",e.target.files)
      const checkSize = await checkFileSize(e);
      if (!checkSize) {
        setCImage(e.target.files)
        const reader = new FileReader();
        // reader.addEventListener("load", () => setUpImg(reader.result));
        reader.addEventListener("load", () => {
          setUpImg(reader.result);
          // Get image dimensions
          const img = new Image();
          img.onload = () => {
            console.log("width_widthwidth_width",img.width,img.height)
            setImageDimensions({ width: img.width, height: img.height });
          };
          img.src = reader.result;
        });
        reader.readAsDataURL(e.target.files[0]);
        setUserInput({
          documentErr: ''
        });
      } else {
        setCImage('');
        setNewUrl('');
        setUserInput({
          documentErr: "Image size must be less than 2 MB",
        });
      }
    }
  };

  const completedCrop = crop => {
    getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
      setNewUrl(res.url);
    })
  }
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      list: {},
      isResponse: '',
      search: '',
      searchType: '',
      inactive: '',
      bP: '',
      totalCount: '',
      activePage: 1,
      dir: '',
      sortValue: '',
      type: '',
      isImageLoader: false

    }
  );

  const handleFileUpload = () => {
    if (upImg) {
      setUpImg('')
      setCrop(cropInitialState)
      image.push({ "url": newUrl })
      getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
        document.push(res);
        addProductImage();
      })
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const addProductImage = () => {
    try {
      setUserInput({ isImageLoader: true });
      let formData = new FormData();
      formData.append("image_upload_to", 1);
      document.forEach(element => {
        formData.append("image", element);
      });
      apiService.addAuctionImage(formData, token).then(result => {
        if (result.data?.status_code == 1) {
          setUserInput({ ...userInput, image: result.data?.response_data?.data.image, isImageLoader: false });
        } else {
        }
      })
    } catch (e) {
      console.error('e', e);
    }
  }

  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      productName: '', productDetail: '', size: '', color: '', fabric: '',category:'',condition:'',
      auction_start_price: '', bid_amount_boost: '',mrp:'',isLinked:'',startPrice:'',bidAmountBoost:'',isLinked,
      auctionId:''
    }
  );

  const handleValidate = () => {
    // debugger
    let validate = true;
    setError({});

    // productName = productName.trim();
    // if ((productName == undefined) || (productName.length == 0)) {
    //   setError({ productName: 'Product name is required' });
    //   validate = false
    // }

    // if (productName) {
    //   if (productName.length < 2) {
    //     setError({ productName: 'Product name must contain at least 2 characters' });
    //     validate = false
    //   } else if (productName.length > 30) {
    //     setError({ productName: 'Product name must contain only 30 characters' });
    //     validate = false
    //   } else {

    //   }
    // }




    const trimmedValueproductName = productName.trim(); // Trim only for validation purposes
    if (!trimmedValueproductName) {
      setError({ productName: 'Product name is required' });
      validate = false
    } else if (trimmedValueproductName.length <= 1) {
      setError({ productName: 'Product name must be between 2 and 30 characters' });
      validate = false
    } else {
     
    }


    // if ((productDetail == undefined) || (productDetail == '')) {
    //   setError({ productDetail: 'Product detail is require' });
    //   validate = false
    // }

    // if (productDetail) {
    //   if (productDetail.length < 2) {
    //     setError({ productDetail: 'Product detail must contain at least 2 characters' });
    //     validate = false
    //   } else if (productDetail.length > 90) {
    //     setError({ productDetail: 'Product detail must contain only 90 characters' });
    //     validate = false
    //   } else {

    //   }
    // }
 
    const trimmedValueproductDetail = productDetail.trim(); // Trim for validation purposes only
    if (!trimmedValueproductDetail) {
      setError({ productDetail: 'Product description is required' });
      validate = false
    } else if (trimmedValueproductDetail.length <= 1) {
      setError({ productDetail: 'Product description must be between 2 and 90 characters' });
      validate = false
    } else {
    }




    if ((category == undefined) || (category.length == 0)) {
      setError({ category: 'category is required' });
      validate = false
    }
    if ((condition == undefined) || (condition.length == 0)) {
      setError({ condition: 'product condition is required' });
      validate = false
    }
    if ((mrp == undefined) || (mrp.length == 0)) {
      setError({ mrp: 'Original MRP is required' });
      validate = false
    }
    if ((startPrice == undefined) || (startPrice.length == 0)) {
      setError({ startPrice: 'Auction Start Price is required' });
      validate = false
    }
    if ((bidAmountBoost == undefined) || (bidAmountBoost.length == 0)) {
      setError({ bidAmountBoost: 'Bid Amount Boost is required' });
      validate = false
    }
    if ((isLinked == undefined) || (isLinked.length == 0) || (isLinked == '')){
      setError({ isLinked: 'Status is required' });
      validate = false
    }
    if((isLinked == "true") && getAuctionList.length>0){
      if ((auctionId == undefined) || (auctionId.length == 0) || (auctionId == '')){
        setError({ auctionId: 'Status detail is required' });
        validate = false
      }
    }
    
    // setAuctionId
    return validate;
  }

  const addProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // debugger
    try {
      if (handleValidate() == true) {
        setBtnLoader(true);
        let req = {
          "name": productName,
          "description": productDetail,
          "category": category,
          "condition": condition,
          "no_exchange_refund": condition,
          "size": size,
          "color": color,
          "image": userInput.image,
          "type": fabric,
          "original_mrp": mrp,
          "auction_start_price": startPrice,
          "bid_amount_boost": bidAmountBoost,
          "is_linked": isLinked
        }
        if (isLinked == "true") {
          req.is_linked = true;
          req.auction_id = auctionId;
        } else {
          req.is_linked = false;
        }
        apiService.addProduct(req, token).then(res => {
          if (res?.data?.status_code == 1) {
            props.updatedProductList();
            props.onlyClose(false);
          } else {
            if (res?.data?.status_code != 1) {
              notify("err", res?.data?.error?.message?.error);
              if(res?.data?.status_code == 2)
                logout();
              if (res?.data?.status_code == 5)
                logout();
              if (res?.data?.status_code == 4)
                logout();
            }
          }
        })

      } else {
        // props.onlyClose(false);
      }
    } catch (e) {
      console.error('e', e);
    }
  }

  const productNameHandleChange12 = (e) => {
    let name =  e.target.value.trim();
    setProductName(name);
    if ((name == undefined) || (name.length == 0)) {
      setError({ productName: 'Product name is required' });
    } else if ((name.length > 0) && (name.length <= 1)) {
      setError({ productName: 'Product name must be between 2 and 30 characters' });
    } else {
      setError({ ...error, [e.target.name]: '', });
    }
  };


  const productNameHandleChange = (e) => {
    const rawValue = e.target.value; // Get the raw value, including spaces
    setProductName(rawValue); // Update the state with the untrimmed value
  
    const trimmedValue = rawValue.trim(); // Trim only for validation purposes
    if (!trimmedValue) {
      setError({ productName: 'Product name is required' });
    } else if (trimmedValue.length <= 1) {
      setError({ productName: 'Product name must be between 2 and 30 characters' });
    } else {
      setError({ ...error, [e.target.name]: '' }); // Clear error
    }
  };

  const productDetailHandleChange1 = (e) => {
    let name =  e.target.value.trim();
    setProductDetail(name);
    if ((name == undefined) || (name.length == 0)) {
      setError({ productDetail: 'Product description is required' });
    } else if ((name.length > 0) && (name.length <= 1)) {
      setError({ productDetail: 'Product description must be between 2 and 90 characters' });
    } else {
      setError({ ...error, [e.target.name]: '', });
    }
  };

  const productDetailHandleChange = (e) => {
    const rawValue = e.target.value; // Keep the raw value for the input field
    setProductDetail(rawValue); // Update the state with the untrimmed value
  
    const trimmedValue = rawValue.trim(); // Trim for validation purposes only
    if (!trimmedValue) {
      setError({ productDetail: 'Product description is required' });
    } else if (trimmedValue.length <= 1) {
      setError({ productDetail: 'Product description must be between 2 and 90 characters' });
    } else {
      setError({ ...error, [e.target.name]: '' }); // Clear the error for the current field
    }
  };
  

  const onChangeCategory = (e) => {
    setCategory(e.target.value)
    auctionList(e.target.value)
    setError({ category: '' });
  };

  const onChangeCondition = (e) => {
    setError({ condition: '' });
    setCondition(e.target.value)
  };

  const onChangeMrp = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, ''); // Remove non-digits
    setMrp(sanitizedValue);
    let errors = { mrp: '', startPrice: '' };
    if (sanitizedValue.length === 0) {
      errors.mrp = 'Original MRP is required';
    } else {
      if (parseInt(sanitizedValue) === 0) {
        errors.mrp = 'Original MRP should not be zero';
      }
      if (startPrice.length > 0 && parseInt(sanitizedValue) < parseInt(startPrice)) {
        errors.startPrice = 'Auction start price should not be more than original MRP';
      }
    }
    setError(errors);
  };

  const onChangeStartPrice = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Remove non-digits
    setStartPrice(sanitizedValue)
    if (sanitizedValue.length > 0) {
      if (parseInt(sanitizedValue) > parseInt(mrp)) {
        setError({ startPrice: 'Auction start price should not more than original MRP' });
      }
      else if (parseInt(sanitizedValue) == 0) {
        setError({ startPrice: 'Auction start price should not be zero' });
      }
      else {
        setError({ startPrice: '' });
      }
    } else {
      setError({ startPrice: 'Auction start price is required' });
    }
  };
  const onChangeBidAmountBoost = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Remove non-digits
    setBidAmountBoost(sanitizedValue)
    if (sanitizedValue.length > 0) {
      setError({ bidAmountBoost: '' });
    } else {
      setError({ bidAmountBoost: 'Bid amount boost is required' });
    }
  };

  const onChangeIsLinked = (e) => {
    setAuctionId('');
    setIsLinked(e.target.value);
    setError({ isLinked: '' })
  };

  const checkFormCompletion = () => {
    if (productName && productDetail && ((category == 1) || (category == 2) || (category == 3) || (category == 4)) && condition && size && color && fabric && mrp && startPrice && bidAmountBoost) {
      if (((isLinked == "true") || (isLinked == true)) && auctionId) {
        setCheckImageLoader(true);
      } else if ((isLinked == "false" || isLinked == false)) {
        setCheckImageLoader(true);
      } else {
        setCheckImageLoader(false);
      }
    } else {
      setCheckImageLoader(false)
    }

    if (productName && productDetail && (category >= 0) && condition && mrp && startPrice && bidAmountBoost) {
      if (((isLinked == "true") || (isLinked == true)) && auctionId) {
        setisAllAdded(true);
      } else if ((isLinked == "false" || isLinked == false)) {
        setisAllAdded(true);
      } else {
        setisAllAdded(false);
      }
    } else {
      setisAllAdded(false);
    }

  };

  useEffect(() => {
    checkFormCompletion()
  }, [productName, productDetail, category, condition, size, color, fabric, mrp, startPrice, bidAmountBoost, userInput.image, isLinked, auctionId])

  const auctionList = (categorieid) => {
    let req = {
      "categorie_id": categorieid ? Number(categorieid) : 0
    };
    apiService.auctionList(req, token).then(res => {
      if (res?.data?.status_code == 1) {
        setAuctionList(res?.data?.response_data.auction_list)
      }
      else {

      }
    })
  }

  const getAuctionId = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      setAuctionId(e.target.value);
      setError({ auctionId: '' });
    } else {
      setAuctionId('')
    }
  };

  const productList = () => {
    let req = {};
    setInputLoader(true)
    setUserInput({ isLoader: true })
    apiService.productList(req, token).then(res => {
      if (res?.data?.status_code == 1) {
        setInputLoader(false);
        setUserInput({ list: res?.data?.response_data.product_list, totalCount: res?.data.response_data.count, isLoader: false })
      }
      else {
        setInputLoader(false);
        setUserInput({
          loading: false
        })
      }
    })
  }

  useEffect(() => {
    productList()
  }, [])

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        className='cstm_modal_product'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className='close_icon' onClick={props.onHide}><span><IoCloseCircle /></span></div>
          <div className='_add_product_modal'>
           
            {
                upImg ? 
                <>
{/* width: '300px', height: '300px', overflow: 'hidden', */}
                  <div className="crop_img" style={{ position: 'relative',  margin: '0px auto' }}>
                    <div className="text-center mb-3 new_crop_btn ">
                      <Button name="Crop" className="done_btn" onClick={handleFileUpload} />
                    </div>
                    <ReactCrop
                      src={upImg}
                      onImageLoaded={img => imgRef.current = img}
                      crop={crop}
                      onChange={(newCrop) => setCrop(newCrop)}
                      onComplete={completedCrop}
                    />
                  </div>

                </> 
                : 
                <>
                 <div className='profile__step_header text-center'>
                  <span className='signup_stps_txt avenir_black w-100 mb-0'>Add Product</span>
                </div>
                <form className='form_box'>
                  {userInput.isImageLoader == true ? <AddproductImg /> :
                    <div className='auction_store_img'>
                      {console.log("newUrl_newUrl_newUrl",newUrl)}
                      <label>
                        {(newUrl.length) ?
                          <img src={newUrl} className='selected_img' />
                          :
                          <div className='img_placholder'>
                            <img src='assets/photo_upld.svg' height={40} width={50} />
                            <p className='mt-3'>Upload Image</p>
                            <p>160*160</p>
                          </div>
                        }
                        <input type="file" size="60" name="document" id="file-input" ref={inputRef} onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                        {/* <span className='auction_pls_icn avenir_medium'>+</span> */}
                           
                  {!(userInput?.image) ?
                      <span className='auction_pls_icn avenir_medium'>+</span>
                    :
                    <span className='auction_pls_icn avenir_medium _edit'  title="160*160"><FaPencil/></span>
                  }
               
                      </label>
                    </div>
                  }
                
                
                  <span className="upload_store_logo_danger avenir_heavy">{userInput.documentErr}</span>
                  <div className='row mt-5'>
                    <div className='col-sm-12'>
                      <Input
                        name="productName"
                        value={productName}
                        placeholder="Product Name"
                        className="form-control"
                        maxLength={30}
                        minLength={10}
                        onChange={productNameHandleChange}
                      />
                      <InlineError className='store_error' errorMessage={error.productName} />
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-sm-12'>
                      <Input
                        name="productDetail"
                        value={productDetail}
                        placeholder="Product Description"
                        className="form-control"
                        maxLength={90}
                        minLength={10}
                        onChange={productDetailHandleChange}
                      />
                      <InlineError className='store_error' errorMessage={error.productDetail} />
                    </div>
                    {/* <label>Please describe your product details.</label> */}
                  </div>
                  <h5 className='mt-5 mb-4'>Product Category</h5>
                  <div className='row'>
                    <div className='col-sm-12'>
                      {/* <label className='mt-1 mb-3'>Choose the product’s category</label> */}
                      <div className='wrap_radio mb-0'>
                        {props.categories.map(categoryItem => (
                          <div key={categoryItem.id}>
                            <input
                              type="radio"
                              className="radio_inpt"
                              value={categoryItem.id}
                              name="category"
                              checked={category == categoryItem.id}
                              onChange={onChangeCategory}
                            />
                            <span className=''>{categoryItem.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <InlineError className='store_error' errorMessage={error.category} />
                  </div>
                  {/* <h3 className='mt-5 mb-4'>Other Details</h3> */}
                  <h5 className='mt-3 '>No exchange/No refund</h5>
                  <div className='row mt-3'>
                    <div className='col-sm-6'>
                      <div className='wrap_radio mb-0'>
                        <div className='_radio_box'>
                          <Input
                            type="radio"
                            className="radio_inpt"
                            name="condition"
                            checked={condition == 1}
                            value={1}
                            onChange={onChangeCondition}
                          />
                          <span className=' '>Yes</span>
                        </div>
                        <div className='_radio_box'>
                          <Input
                            type="radio"
                            className="radio_inpt"
                            name="condition"
                            checked={condition == 0}
                            value={0}
                            onChange={onChangeCondition}
                          />
                          <span className=' '>No</span>
                        </div>
                      </div>
                      <InlineError className='store_error' errorMessage={error.condition} />
                    </div>
                  </div>
                  <h5 className='mt-3 '>Product Condition</h5>
                  <div className='mt-3' style={{fontWeight:300}}>
                    <input type="checkbox" id="asShownOnLive" name="asShownOnLive" defaultChecked disabled />&nbsp;&nbsp;&nbsp;&nbsp;As shown on live
                  </div>
                  <div className='row mt-5'>
                    <div className='col-sm-6'>
                      <Input
                        name="originalMrp"
                        className='form-control'
                        placeholder="Original MRP"
                        maxLength={20}
                        minLength={1}
                        onChange={onChangeMrp}
                        value={mrp}
                      />
                      <InlineError className='store_error' errorMessage={error.mrp} />
                    </div>
                    <div className='col-sm-6'>
                      <Input
                        name="auctionStartPrice"
                        className='form-control'
                        placeholder="Auction Start Price"
                        maxLength={20}
                        minLength={1}
                        onChange={onChangeStartPrice}
                        value={startPrice}
                      />
                      <InlineError className='store_error' errorMessage={error.startPrice} />
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-sm-12'>
                      <Input
                        name="bidAmountBoost"
                        value={bidAmountBoost}
                        placeholder="Bid Amount Boost"
                        maxLength={20}
                        minLength={1}
                        className="form-control"
                        onChange={onChangeBidAmountBoost}
                      />
                      <InlineError className='store_error' errorMessage={error.bidAmountBoost} />
                    </div>
                    {/* <label>Specify the amount you want to increase at the time of bidding</label> */}
                  </div>
                  <div className='row mt-5'>
                    <div className='col-sm-10'>
                      <h5>Status</h5>
                      <div className='wrap_radio mb-0 mt-3'>
                        <div className='_radio_box'>
                          <Input
                            type="radio"
                            className="radio_inpt"
                            value={true}
                            name="linked"
                            checked={isLinked == true}
                            onChange={onChangeIsLinked}
                          />
                          <span className=''>Link to Auction</span>
                        </div>
                        <div className='_radio_box'>
                          <Input
                            type="radio"
                            className="radio_inpt"
                            value={false}
                            name="linked"
                            checked={isLinked == false}
                            onChange={onChangeIsLinked}
                          />
                          <span className=''>Not Scheduled for Auction</span>
                        </div>
                      </div>
                      <InlineError className='store_error' errorMessage={error.isLinked} />
                    </div>
                    <div className='col-sm-12 mt-4'>
                      {
                        ((isLinked == "true") || (isLinked == true)) ?
                        <>
                          <select
                            name="auctionList"
                            className='form-select'
                            onChange={(e) => getAuctionId(e)}
                            value={auctionId ? auctionId : ''}
                          >
                            {
                              getAuctionList.length ?
                                <>
                                  <option >List of Auctions</option>
                                  {getAuctionList.map(auction => (
                                    <option key={auction.id} value={auction.id}>
                                      {auction.description}
                                    </option>
                                  ))}
                                </>
                                :
                                <>
                                  <option >Auction not created for selected category</option>
                                </>
                            }
                          </select>
                          {getAuctionList.length?<InlineError className='store_error' errorMessage={error.auctionId}/>:''}
                          </>
                          :
                          <></>
                      }
                      
                    </div>
                  </div>
                  <div className='mt-4 text-center'>
                    {/* {
                      (isAllAdded == true) ? */}
                        <Button
                          name={btnLoader ? <BtnLoader /> : "Submit"}
                          onClick={(e) => addProduct(e)}
                        />
                        {/* :
                        <Button
                          className="mt-4"
                          name={"Submit"}
                          disabled="true"
                        />
                    } */}
                  </div>
                
                {/* } */}
                </form>
                </>
             }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddProductModal;