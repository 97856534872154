import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function GraphSection({ dasboardDetails, data }) {
  const width1 = dasboardDetails?.buyer_footfall_on_lives?.returning_visitor.toFixed();
  const width2 = dasboardDetails?.buyer_footfall_on_lives?.old_visitor.toFixed();
  const dates = data?.map(item => item.date);
  const profits = data?.map(item => item.profit);
  const highchartdata = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Daily Profit'
    },
    xAxis: {
      categories: dates
    },
    yAxis: {
      title: {
        text: 'Profit'
      }
    },
    series: [
      {
        name: 'Profit Graph (Last 15 Days)',
        data: profits
      }
    ],
    colors: ['#F17721', '#eeeeff']
  };

  return (
    <>
      <div className='_left'>
        <h3 className='mt-5'>Buyer Footfall on Lives</h3>
        <div className='wrap_radio mb-0 mt-3 mb-5 justify-content-center'>

          <div className='_radio_box me-4'>
          {/* <label className="custom_radio">
            <input
              type="radio"
              className="radio_inpt"
              name="linked"
              checked={false}
            />
             </label> */}
             <span className='__fill_circle' style={{background:'#fff'}}></span>
            <span className=''>Returning Visitors</span>
          </div>
          <div className='_radio_box me-0'>
          {/* <label className="custom_radio">
            <input
              type="radio"
              className="radio_inpt"
              name="linked"
              checked
            />
             </label> */}
             <span className='__fill_circle'></span>
            <span className=''>New Visitors</span>
          </div>
        </div>
        <div className='_box'>
          {width1 > 0 && <div className="_white" style={{ width: `${width1 == width2 ? '50' : width1}%` }}>{width1}%</div>}
          {width2 > 0 && <div className="_blue" style={{ width: `${width1 == width2 ? '50' : width2}%` }}>{width2}%</div>}
        </div>
      </div>
      <div className='_right'>
        <HighchartsReact highcharts={Highcharts} options={highchartdata} />
      </div>
    </>
  )
}

export default GraphSection