/**
 * @about this file contains api 
 */

import axios from 'axios';
import Api from './Api';


export const apiService = {
    registerFirstStep, sendOtp, verifyOtp, setusername, addStoreInfo, checkGSTVerification, categoryList, userWebLogin, addCategory, checkIfscCode,
    addBankAccount, setNewPassword, settingNotification, getSettingNotification, deleteSeller, logout, checkUsername, addStoreImage, addAuction, addAuctionImage, auctionList, getAuction, editAuction, getAuctionSlot, addProduct,
    productList, getProduct, editProduct, addProductToAuction, auctionProductList, reorderProduct, meetingSessionApi, orderList, orderListFilter, dashboardList, orderDetail, cancelOrder, rejectCalcelRequest, approveCalcelRequest, auctionSetup,
    addYoutubeUrl, stopYoutubeUrl, getLabelDetail, orderReturn, setYoutubeUrl, getUserDetails, notificationDetails, editUserDetails, getStoreDetails, getYoutubeUrl, downloadOrderInvoice, notificationRead, sendOtpNew,
    verifyOtpForNumber, verifyOtpForEmail, sendOtpForEmail
}

const headersApplicationJson = { 'Content-Type': 'application/json', 'X-CSRFToken': 'MVv1sXbqZg58yTIbgS0cauC75z6rSMs74G2rFC5MkMxC5fisksdevpHpFzuhRsMC' }
const headersMultipart = { "Content-type": "multipart/form-data", 'X-CSRFToken': 'uOM3k1i6WbWetthMoIau2Tq2DTszUcc9MzjtxGcshHoI0PR3sinwnOvkdTQpTSwE' }

axios.defaults.headers.common['Authorization'] = `${Api.AUTH}`;

async function userLogin(params) { return axios.post(Api.LOGIN, params, { headers: headersApplicationJson }) }

async function registerFirstStep(params) { return axios.post(Api.REGISTER_FIRST_STEP, params, { headers: headersApplicationJson }) }

async function verifyOtp(params) { return axios.post(Api.VERIFY_OTP, params, { headers: headersApplicationJson }) }

async function verifyOtpForNumber(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.VERIFY_OTP_FOR_NUMBER, params, { headers: headersApplicationJson })
}

async function verifyOtpForEmail(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.VERIFY_OTP_FOR_EMAIL, params, { headers: headersApplicationJson })
}


async function sendOtp(params) { return axios.post(Api.SEND_OTP, params, { headers: headersApplicationJson }) }


async function sendOtpForEmail(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.SEND_OTP_FOR_EMAIL, params, { headers: headersApplicationJson })
}

// async function sendOtpNew(params) { return axios.post(Api.SEND_OTP_NEW, params, { headers: headersApplicationJson }) }

async function sendOtpNew(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.SEND_OTP_NEW, params, { headers: headersApplicationJson })
}

async function setusername(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.SET_USERNAME, params, { headers: headersApplicationJson })
}

async function addStoreInfo(params, token) {
    headersMultipart['token'] = token;
    return axios.post(Api.ADD_STORE_INFO, params, { headers: headersMultipart })
}

async function checkGSTVerification(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.CHECK_GST_VERIFICATION, params, { headers: headersApplicationJson })
}

async function categoryList(params) { return axios.get(Api.CATEGORY_LIST, params, { headers: headersApplicationJson }) }

async function addCategory(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.ADD_CATEGORY, params, { headers: headersApplicationJson })
}
async function userWebLogin(params) {
    return axios.post(Api.USER_LOGIN, params, { headers: headersApplicationJson })
}

async function checkIfscCode(params) { return axios.get(`${Api.CHECK_IFSC_CODE}?ifsc=${params.ifsc}`, params, { headers: headersApplicationJson }) }

async function addBankAccount(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.ADD_BANK_ACCOUNT, params, { headers: headersApplicationJson })
}

async function setNewPassword(params, token) {
    return axios.put(Api.SET_NEW_PASSWORD, params, { headers: headersApplicationJson })
}

async function settingNotification(params, token) {
    headersApplicationJson['token'] = token
    return axios.put(Api.SETTING_NOTIFICATION, params, { headers: headersApplicationJson })
}

async function getSettingNotification(token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.GET_SETTING_NOTIFICATION}`, { headers: headersApplicationJson })
}

async function deleteSeller(token) {
    headersApplicationJson['token'] = token;
    return axios.delete(Api.DELETE_SELLER, { headers: headersApplicationJson })
}

async function logout(token) {
    headersApplicationJson['token'] = token;
    return axios.delete(Api.LOGOUT, { headers: headersApplicationJson })
}

async function checkUsername(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.CHECK_USERNAME}?user_name=${params.username}`, { headers: headersApplicationJson })
}

async function addStoreImage(params, token) {
    headersMultipart['token'] = token;
    return axios.post(Api.ADD_STORE_IMAGE, params, { headers: headersMultipart })
}

async function addAuction(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.ADD_AUCTION, params, { headers: headersApplicationJson })
}

async function addAuctionImage(params, token) {
    headersMultipart['token'] = token;
    return axios.post(Api.ADD_AUCTION_IMAGE, params, { headers: headersMultipart })
}

async function auctionList(params, token) {
    console.log("params auction", params)
    headersApplicationJson['token'] = token;
    if (params.search)
        return axios.get(`${Api.AUCTION_LIST}?categorie_id=${params.categorie_id}&search=${params.search}&limit=${params.limit}&offset=${params.offset}`, { headers: headersApplicationJson })
    else if (params.isAuctionCompleted)
        return axios.get(`${Api.AUCTION_LIST}?categorie_id=${params.categorie_id}&is_auction_completed=${params.isAuctionCompleted}&limit=${params.limit}&offset=${params.offset}`, { headers: headersApplicationJson })
    else
        return axios.get(`${Api.AUCTION_LIST}?categorie_id=${params.categorie_id}&is_auction_completed=${params.isAuctionCompleted}&limit=${params.limit}&offset=${params.offset}`, { headers: headersApplicationJson })
}

async function getAuction(id, token) {
    headersApplicationJson['token'] = token;
    return axios.get(Api.GET_AUCTION.replace("{{id}}", id), { headers: headersApplicationJson })
}

async function editAuction(params, token) {
    headersMultipart['token'] = token;
    return axios.put(Api.EDIT_AUCTION, params, { headers: headersMultipart })
}

async function getAuctionSlot(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.GET_AUCTION_SLOT}?date=${params.date}`, { headers: headersApplicationJson })

}
async function addProduct(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.ADD_PRODUCT, params, { headers: headersApplicationJson })
}

async function productList(params, token) {
    headersApplicationJson['token'] = token;
    if (params.search) {
        if (params.categories)
            return axios.get(`${Api.PRODUCT_LIST}?search=${params.search}&categories=${params.categories}&limit=${params.limit}&offset=${params.offset}`, { headers: headersApplicationJson })
        else
            return axios.get(`${Api.PRODUCT_LIST}?search=${params.search}&limit=${params.limit}&offset=${params.offset}`, { headers: headersApplicationJson })
    }
    else if (params.categories)
        return axios.get(`${Api.PRODUCT_LIST}?categories=${params.categories}&limit=${params.limit}&offset=${params.offset}`, { headers: headersApplicationJson })
    else
        return axios.get(`${Api.PRODUCT_LIST}?limit=${params.limit}&offset=${params.offset}`, { headers: headersApplicationJson })
}

async function getProduct(id, token) {
    headersApplicationJson['token'] = token;
    return axios.get(Api.GET_PRODUCT.replace("{{id}}", id), { headers: headersApplicationJson })
}

async function editProduct(params, token) {
    headersMultipart['token'] = token;
    return axios.put(Api.EDIT_PRODUCT, params, { headers: headersMultipart })
}

async function addProductToAuction(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.ADD_PRODUCT_TO_AUCTION, params, { headers: headersApplicationJson })
}

async function auctionProductList(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.AUCTION_PRODUCT_LIST}?auction_id=${params.auctionId}`, { headers: headersApplicationJson })
}

async function reorderProduct(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.REORDER_PRODUCT, params, { headers: headersApplicationJson })
}

async function meetingSessionApi1(params, token) {
    const myHeaders = new Headers();
    myHeaders.append("token", "2l1NXPxVCYddH1xMeIUxa15o0jS8Q7d4DUQRDWis4LMhVvqr9czoLBlZltgpvg8y");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Basic abcd=");

    const urlencoded = new URLSearchParams();
    urlencoded.append("id1", "122");
    urlencoded.append("id2", "123");

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
    };

    return fetch("https://devapi.bidsboxx.com/api/v1/audio/channel/join/meeting", requestOptions)
        .then((response) => { return response.json() })
        .catch((error) => console.error(error));
}


async function meetingSessionApi(id, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.MEETING_SESSION_API}?auction_id=${id}`, { headers: headersApplicationJson })
}

async function attendeeAdd(params, token) {
    return axios.post(Api.ATTENDEE_ADD_API, params, { headers: headersApplicationJson })
}

async function auctionSetup(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.AUCTION_SETUP, params, { headers: headersApplicationJson })
}
async function orderList(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.ORDER_LIST}?is_seller=true`, { headers: headersApplicationJson })
}
async function orderListFilter(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.ORDER_LIST_FILTER}?order_status=${params.order_status}&time_duration=${params.time_duration}`, { headers: headersApplicationJson })
}

async function dashboardList(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.DASHBOARD_LIST}?days=${params.days}`, { headers: headersApplicationJson })
}

async function orderDetail(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.ORDER_DETAIL}?order_id=${params.id}`, { headers: headersApplicationJson })
}
async function cancelOrder(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.CANCEL_ORDER, params, { headers: headersApplicationJson })
}
async function approveCalcelRequest(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.APPROVED_CANCELLATION, params, { headers: headersApplicationJson })
}
async function rejectCalcelRequest(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.REJECT_CANCELLATION, params, { headers: headersApplicationJson })
}

async function addYoutubeUrl(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.ADD_YOUTUBE_URL}?meeting_url=${params.url}&rtmp_endpoint=${params.endpoint}&auction_id=${params.auction_id}`, { headers: headersApplicationJson })
}

async function stopYoutubeUrl(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.STOP_YOUTUBE_URL}?auction_id=${params.auction_id}`, { headers: headersApplicationJson })
}

async function setYoutubeUrl(params, token) {
    headersApplicationJson['token'] = token;
    return axios.post(Api.SET_YOUTUBE_URL, params, { headers: headersApplicationJson })
}

async function getUserDetails(token) {
    headersApplicationJson['token'] = token;
    return axios.get(Api.GET_USER_DETAILS, { headers: headersApplicationJson })

}
async function notificationDetails(token) {
    headersApplicationJson['token'] = token;
    return axios.get(Api.GET_NOTIFICATION_DETAILS, { headers: headersApplicationJson })
}
async function getLabelDetail(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.GET_LABEL}?order_no=${params.order_no}`, { headers: headersApplicationJson })
}
async function orderReturn(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.ORDER_RETURN, params, { headers: headersApplicationJson })
}
async function editUserDetails(params, token) {
    headersApplicationJson['token'] = token;
    return axios.put(Api.EDIT_USER_DETAILS, params, { headers: headersApplicationJson })
}

async function getStoreDetails(token) {
    headersApplicationJson['token'] = token;
    return axios.get(Api.GET_STORE_DETAILS, { headers: headersApplicationJson })

}

async function getYoutubeUrl(params) {
    return axios.get(`${Api.GET_YOUTUBE_URL}?auction_id=${params.id}`, { headers: headersApplicationJson })
}

async function downloadOrderInvoice(params, token) {
    headersApplicationJson['token'] = token;
    return axios.get(`${Api.GET_DOWNLOAD_ORDER_INVOICE}?order_id=${params.id}`, { headers: headersApplicationJson })
}

async function notificationRead(token) {
    headersApplicationJson['token'] = token;
    return axios.put(`${Api.NOTIFICATION_READ}?is_seller=true`, {}, { headers: headersApplicationJson })
}

export { userLogin, meetingSessionApi, attendeeAdd }