import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function TermsConditions() {
  const navigate = useNavigate();
  const goBack = () => { navigate(-1); };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='privacy_policy_parent'>
      <div className='header'>
        <span onClick={goBack}><MdArrowBackIos /></span>
        <Link to="/">  <img src='https://d2rqcufsarnep3.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
      </div>
      <div className="privacy_policy">
        <h4>Terms and Conditions</h4>
        <p>
          Vallabh bidsboxx Private Limited operates under the domain name bidsboxx.com and is a registered entity in India with the Corporate Identification Number – CIN U46109HR2024PTC121384. Our company is committed to providing a secure and efficient platform for live shopping, ensuring transparency and fairness in all transactions. All business activities conducted on our platform are governed by the laws of India, and we adhere strictly to all legal and regulatory requirements.
        </p>
        <p>
          Please read these terms and conditions ("Terms") carefully before accessing or using the Platform (defined hereinafter). These Terms, along with the Privacy Policy available on the Platform ("Privacy Policy") and other policies (as may be notified/displayed/published on the Platform), form the contract between the Users of this Platform and the Company (collectively, "Agreement"). By using the Platform, Users agree to be bound by this Agreement as posted on the Platform from time to time.
        </p>
        <h5>1. ABOUT THE TERMS</h5>
        <div className='content'>
          <h6>1.1 What is Bidsboxx and who operates it?</h6>
          <div className='inner_content'>
            <p>
              1. Bidsboxx is an application that provides an online marketplace ("Application") where registered suppliers ("Suppliers") can offer to sell their products to registered Users of the Application, including resellers ("Resellers").
            </p>
            <p>
              2. The application and the website at bidsboxx.com (collectively referred to as the "Platform") are operated by Vallabh Bidsboxx Private Limited.
            </p>
            <p>
              The Company’s role is limited to managing the Application and associated marketing, facilitating payment collections, fulfillment, order management, inquiry management, and other incidental services to enable transactions between the Suppliers and the Users ("Services").
            </p>
            <p>
              3. Services are not available on the Website, and to avail the same, Users are required to install the Application.
            </p>

          </div>
        </div>

        <div className='content'>
          <h6>1.2 When are these Terms applicable and binding on Users?</h6>
          <div className='inner_content'>
            <p>
              1. The Agreement is applicable to any person when they install, download, or merely visit or access any part of the Platform or utilize the Services. Such persons are referred to as users, including, without limitation, users who are browsers, Suppliers, Resellers, merchants, other purchasers, or contributors of content (collectively, "User").
            </p>
            <p>
              2. The Agreement between User and Company becomes effective on the date on which the Application is downloaded, the Website is accessed, and/or the terms of the Agreement are updated, creating a legally binding arrangement between the User and the Company.
            </p>
          </div>
        </div>


        <div className='content'>
          <h6>1.3 Can the terms of this Agreement be modified?</h6>
          <div className='inner_content'>
            <p>
              1. Users can review the most current version of the Agreement at any time on the Website. The Company reserves the right to unilaterally update, change, or replace any part of the Agreement by publishing updates or changes on the Platform, and such amended provisions of the Agreement shall be effective immediately upon being posted on the Platform.
            </p>
            <p>
              2. It is the responsibility of the Users to check this page periodically for changes. The Users’ continued use of or access to the Application following the posting of any changes constitutes acceptance of those changes.
            </p>
          </div></div>


        <div className='content'>
          <h6>1.4 What if the terms of the Agreement are not acceptable to Users?</h6>
          <div className='inner_content'>
            <p>
              1. If the User does not agree with the terms of the Agreement, the User is advised to refrain from using the Platform. By using the Services, it is signified that the User agrees to abide by the terms of the Agreement (as updated from time to time).
            </p>
          </div></div>



        <h5>2. ACCOUNT REGISTRATION, SUSPENSION, AND TERMINATION</h5>

        <div className='content'>
          <h6>2.1 Does a User necessarily need to create an account on the Platform?</h6>
          <div className='inner_content'>
            <p>
              1. The Company does not permit Users to avail themselves of the Services on the Platform without prior registration. Users may access the Application by registering to create an account and become a member. The membership is limited to the purpose of buying or selling products, is subject to this Agreement, and is strictly non-transferable.
            </p>
          </div></div>

        <div className='content'>


          <h6>2.2 For the use of the Platform, is a User subject to any eligibility criteria?</h6>
          <div className='inner_content'>
            <p>
              1. The Services on the Platform shall be availed by Users who can form legally binding contracts under the Indian Contract Act, 1872, and are at least eighteen (18) years of age.
            </p>
            <p>
              2. The Company reserves the right to terminate the User’s account and/or deny access to the Platform if it is brought to the Company’s notice or if it is discovered that the User does not meet the conditions herein. Users accessing or using the Platform represent and warrant that they have the right to access or use the Platform.
            </p>
          </div></div>


        <div className='content'>

          <h6>2.3 Are there any specific requirements for registering an account on the Platform?</h6>
          <div className='inner_content'>
            <p>
              1. Users are required to enter a valid phone number while registering on the Platform. By such registration, Users consent to be contacted by the Company via phone calls, SMS notifications, instant messages, or other means of communication for subscription/services/promotional updates, etc. Users may 'opt out' of such subscription/service/promotional updates either through the 'opt-out' means provided or by writing to the support team.
            </p>
            <p>
              2. It is the responsibility of the Users to provide the correct mobile number so that the Company can communicate with the Users via SMS. Users understand and agree that if the Company sends an SMS, but the Users do not receive it because the Users’ mobile number is incorrect, out of date, blocked by the User's service provider, or the Users are otherwise unable to receive SMS, the Company shall be deemed to have provided the communication to the Users effectively.
            </p>
          </div></div>


        <div className='content'>


          <h6>2.4 Can a User account registered on the Platform be suspended or terminated?</h6>
          <div className='inner_content'>
            <p>
              1. The Company reserves the right to suspend or terminate the account or access to Services (or any part thereof) on the Application, including blocking any amounts due to the User and associated account without notice. Users will remain liable for all amounts due up to and including the date of termination, if:
            </p>
            <ul>
              <li>
                Any information provided during the registration process or thereafter proves to be inaccurate, not current, or incomplete; and/or
              </li>
              <li>
                In the Company’s assessment, the User has:
                <ul>
                  <li>Charged an unreasonably high price;</li>
                  <li>Had unreasonable instances of returns and/or cancellations initiated;</li>
                  <li>Engaged in actions that are unlawful, fraudulent, negligent, or derogatory to the Company’s interests;</li>
                  <li>Failed or is suspected to have failed to comply with any term or provision of the Agreement or applicable law.</li>
                </ul>
              </li>
              <li>The User is found to be non-compliant with the Agreement.</li>
            </ul>
            <p>
              2. Further, where the violation of the Agreement gives rise to criminal or civil action, the Company may at its sole discretion pursue such action.
            </p>
            <p>
              3. Without prejudice to the above-stated rights of the Company, in the case of alleged fraud or other breaches of this Agreement by the User, the Company may at its sole discretion (a) withhold all amounts payable to such User; and (b) impose penalties as the Company may reasonably determine and set off such penalties from the monies payable by the Company to such User.
            </p>

          </div></div>


        <div className='content'>

          <h6>2.5 What are User obligations vis-à-vis their registered account on the Platform?</h6>
          <div className='inner_content'>
            <p>
              1. Having an account on the Platform gives authenticity to the actions of the User. It means that the User is solely responsible for all activities that occur under its account and that all transactions made by such User are intended for bona fide sale or consumption in the course of their business activities.
            </p>
            <p>
              2. Any and every activity undertaken by a User under their account shall be the sole responsibility of such User, and the Company shall not be liable for such activity in any manner. Hence, it shall be the responsibility of the User to treat the user identification code, password, and any other piece of information that is provided by the Company as part of the security procedures, as confidential and not disclose the same to any person or entity other than the Company.
            </p>
            <p>
              3. User acknowledges and agrees that having an account on the Platform does not grant it any rights on the Platform not specifically granted to them by the Company, and that the User has no ownership or other interest in the account. The User understands that all rights in and to the account are and shall forever be owned by and inure to the benefit of the Company.
            </p>
            <p>
              4. Upon registration, Users may receive a password-protected account and an identification. The Users agree to:
            </p>
            <ul>
              <li>Maintain the confidentiality of their password, if applicable;</li>
              <li>Take full responsibility for all activities by Users accessing the Application through their account;</li>
              <li>
                Immediately notify the Company of any unauthorized use of their account or any other breach of security that they become aware of; and
              </li>
              <li>Ensure that they exit from their account at the end of each session.</li>
            </ul>
          </div></div>
        <h5>3. PLACING ORDERS AND FINANCIAL TERMS</h5>
        <div className='content'>
          <h6>3.1 How does order placement work on the Platform?</h6>
          <div className='inner_content'>

            <p>
              1. The Application allows Users to place orders for products listed by Suppliers on the Application, and subject to this Agreement, facilitates the placement of orders for the products by the Users.
            </p>
            <p>
              2. Upon receiving an order from a User, the Company shall electronically send a confirmation of such order to the Supplier and the User concerned. Additionally, the Company may inform the User about the availability, unavailability, or change in price of the order as informed by the Supplier from time to time. Confirmation of the order by the Supplier shall be considered final.
            </p>
            <p>
              3. The Company does not own, sell, or resell any products on its own, nor does it control the Suppliers. It only facilitates the transaction between buyers and sellers, including Users and Suppliers, as a 'marketplace'. The Company makes all reasonable efforts to promptly update the User’s account and other information to facilitate transaction completion. Hence, Users are required to provide current, complete, and accurate purchase and account information for all purchases made on the Application.
            </p>
            <p>
              4. Additionally, the fulfillment of orders to their end users/consumers by a Reseller shall be the responsibility of the Reseller in relation to such end user/consumer. Any add-on service provided by the Platform towards such an order shall be provided merely as a service provider of such Reseller by the Platform, and accordingly, the Platform shall not have any privity of contract with such end user/consumer.
            </p>
          </div></div>

        <div className='content'>


          <h6>3.2 How are the commercial terms fixed on the Application?</h6>
          <div className='inner_content'>

            <p>
              1. All commercial/contractual terms of sale are offered by Suppliers and agreed to between Suppliers and the Users alone. The commercial/contractual terms include, without limitation, price, date, period and mode of delivery, warranties related to products, etc. The Company does not have any control over or involvement in the offering or acceptance of such commercial/contractual terms between the Suppliers and the Users.
            </p>
            <p>
              2. Similarly, in the case of deliveries effected by Resellers using the Platform, the Platform only acts as a service provider for the Reseller, facilitating delivery or other services related to an order. The Company does not have any control over or involvement in the offering or acceptance of such commercial/contractual terms between the Reseller and its end user/consumer.
            </p>
            <p>
              3. Policies related to returns/exchanges, penalties, refunds, and cancellations will be updated in the Application from time to time. The Company reserves the right to change these policies as required in the Application without any permission from the Users.
            </p>
            <p>
              4. The Company also reserves the right to withhold benefits such as COD payments and the right to claim refunds from time to time at its sole discretion owing to internal reasons or external factors.
            </p>

          </div></div>

        <div className='content'>

          <h6>3.3 How does payment and settlement of payment work on the Platform?</h6>
          <div className='inner_content'>
            <p>
              1. Users acknowledge and agree that the Company may, at the request of the Supplier or the Reseller, act as the payment agent for the limited purpose of accepting payments on behalf of such Suppliers and Resellers. Users understand, accept, and agree that the payment facility provided by the Company is neither a banking nor financial service but is merely a facilitator providing a third-party payment processor for the transactions on the Application. Further, by providing a payment facility, the Company is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transaction or the transaction price. The Company will not be liable for any charges made by the Users' bank in relation to the payment of the total amount.
            </p>
            <p>
              2. In connection with any order, information such as name, transaction details, device type, Platform usage details, PAN number, payment details, billing address, and credit card information may need to be provided either to the Company or the third-party payment processor. If Users are directed to the third-party payment processor, they may be subject to terms and conditions governing the use of that third party’s service and that third party’s personal information collection practices. Users are requested to review such terms and conditions and privacy policies before using the Application. In the case of a Reseller providing billing information, delivery address, or other contact information of its end user/consumer to the Company or other delivery service provider, the Reseller shall ensure that it has the necessary consents and approvals from the respective end user/consumer as required under applicable law.
            </p>
            <p>
              3. The Company merely collects the payment on behalf of the Supplier or Reseller, as the case may be. All applicable taxes and levies, the rates thereof, and the manner of applicability of such taxes are to be charged and determined by the Supplier or Reseller. The Company holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole liability with respect to any legal issue arising on the taxes payable shall be with the Reseller.
            </p>
            <p>
              4. The transaction is bilateral between Suppliers & Users and/or Resellers & end users/consumers ("User Transactions"), and the Company is not liable to charge or deposit any taxes applicable on such transactions.
            </p>
            <p>
              5. The User may provide the bank account details to receive any refunds, margins, referrals, and/or other payments which may be owed to the User by Bidsboxx. While a User is permitted to update the bank account details, the following conditions will apply:
            </p>
            <ul>
              <li>
                a. The user cannot edit or update the bank account details if the bank details already provided are valid and there is no pending payment to the User from Bidsboxx.
              </li>
              <li>
                b. The user cannot edit or update the bank account details if there is a pending transaction that is being processed to the existing bank account.
              </li>
              <li>
                c. The user cannot edit or update the bank account details if the new account number being inserted is already in use in another User’s account. The User can only attempt to edit or update the bank account details three times in a day. If the User fails to update the bank account within three attempts, the User will have to wait for 24 hours to attempt to update the bank account details again. Bidsboxx may also designate a total number of edits or updates a User can carry out to the bank account details in the lifetime of the account holder.
              </li>
            </ul>
            <p>
              6. In the event that:
            </p>
            <ul>
              <li>
                a. A User has breached the total limit to edit or update the bank account details, and
              </li>
              <li>
                b. The same bank account is used for more than ten platform accounts, the User will have to contact customer support in order to update the bank account details again. Bidsboxx reserves the right to change this policy on editing and updating the bank account at its sole discretion.
              </li>
            </ul>
            <p>
              7. Bidsboxx reserves the right to block cash on delivery (COD) payments from time to time at its sole discretion for reasons including but not limited to high product value and other such reasons or factors (internal or external) that Bidsboxx may deem fit.
            </p>

          </div></div>

        <div className='content'>
          <h6>3.4 Whether Company charges User(s) for Services provided by Company on the Platform?</h6>
          <div className='inner_content'>

            <p>
              1. Services on the Platform may require payment of charges, the rate of which shall be solely at the discretion of the Company and shall be subject to User approval at the time of placing an order on the Platform. The Company reserves the right to revise charges towards the Service at any time at its sole discretion. The charge applicable at any given time will be the charge displayed at the time of purchase/booking of the respective Service (if any) by the User on the Platform.
            </p>
            <p>
              2. The Company reserves the right to introduce additional chargeable services on the Platform, including charges for a premium return service, cancellation charges, cash on delivery handling fees, etc.
            </p>

          </div></div>

        <div className='content'>

          <h6>3.5 Whether for transacting on Platform, User is required to be registered under the Central or State Goods and Services Tax Legislations ("GST Laws")?</h6>
          <div className='inner_content'>
            <p>
              1. The Company is not obligated towards any direct or indirect tax obligation of the User that may arise as a result of the User's access or use of Services on the Platform. The requirement for registration and compliance under the GST Laws and other tax laws is the sole responsibility of the User, including the Reseller. The Company is not liable for any omissions or commissions by such User who acts in violation of any applicable law. Accordingly, Users are advised to seek independent tax advice relating to their business and/or transactions through the Platform, including whether they are liable for GST registration.
            </p>

            <h6>3.6(A) What are the terms and conditions regarding the offers and benefits provided on the Platform and advertised by Bidsboxx?</h6>
            <p>
              1. Subject to the below and unless otherwise mentioned, all product discounts and offers are by the Suppliers and not by the Company (“Supplier Offers”).
            </p>
            <p>
              2. From time to time, we may conduct various types of marketing and promotional campaigns, which may include offers including referrals, discounts, and other promotional offers to be used on our platform (“Bidsboxx Offers”).
            </p>
            <p>
              3. Bidsboxx Offers shall be subject to the terms and conditions which are solely determined by us, and the terms of such discounts and offers may vary for customers based on factors relating to the customer such as usage of the platform, volume of transactions, time spent on the platform, city, place of residence, time, etc.
            </p>
            <p>
              4. We reserve the right to make these offers available for a limited time period, limit the value of the offer to a fixed amount or a fixed percentage determined at our discretion, and to void, amend, discontinue, cancel, or reject the use of any of the offers, discounts, or promotional offers, including any aspect or feature of such offers at any time without prior intimation.
            </p>
            <p>
              5. We reserve the right to decide and vary the timing and dates of the offer, the value of the offer amount, the limit of the offer amount or offer percentage, the conditions subject to which the offer can be availed or will be offered, the number and category of products available for the offer, at any time, subject to our absolute discretion.
            </p>
            <p>
              6. The offers, discounts, and promotional offers or any terms thereof may be changed or amended from time to time.
            </p>
            <p>
              7. We reserve the right to make certain offers valid for limited inventory and subject to the availability of the product at the time of booking.
            </p>
            <p>
              8. Certain offers may apply only to products covered under such promotional offers. It will not be applicable to the same or similar products sold by the same Supplier or other Suppliers but not covered under offers. These offers will operate at Bidsboxx’s discretion on a first-come-first-served basis. Once the offer product stocks are over, you may purchase the same product on the platform outside the promotional offers available.
            </p>
            <p>
              9. Bidsboxx, at its own discretion, reserves the right to cancel your option to return or exchange any product purchased under offers and discounts.
            </p>
            <p>
              10. It is your responsibility to review and comply with the terms and conditions governing the offers, discounts, and other promotional offers provided on our platform.
            </p>
            <p>
              11. Any of the offers, discounts, or promotional offers may not be valid when used in conjunction with other promotional offers or vouchers unless explicitly permitted by us or the Supplier.
            </p>
            <p>
              12. The offers, discounts, and promotional offers cannot be exchanged for cash and can only be availed in accordance with the terms and conditions of the offers, unless otherwise communicated.
            </p>
            <p>
              13. Certain offers, discounts, and promotions offered, such as first order discounts, reactivation discounts, etc., may be applied at the cart level, and in the event that multiple products are purchased in a single transaction, then such offer, discount, or promotion may be divided and applied to each product in the cart in a manner determined by us.
            </p>
            <p>
              14. Subject to Bidsboxx’s Cancellation and/or Return, Refund, and Replacement policy, if the User: (i) cancels any product which is subject to any promotions, offers, or discounts; or (ii) returns any product purchased which is subject to any promotions, offers, or discounts, the User will be eligible to receive only the refund of the amount paid by the User on the purchase of such product.
            </p>
            <p>
              15. In the event any product is canceled or returned in accordance with Bidsboxx's Cancellation and/or Return, Refund, and Replacement Policy, then save and except as provided herein, any offer, promotion, or discount applied to such product shall be forfeited.
            </p>
            <p>
              16. We and/or the Supplier shall have no liability with respect to the offers, discounts, and promotional offers on the Platform.
            </p>
            <p>
              17. In the event of indication of any fraudulent or suspicious behavior or activity by you on the platform or in relation to the platform, we reserve the right at our discretion to deny you the right to claim or participate in any offer, promotion, discount, or campaign.
            </p>

          </div></div>

        <div className='content'>

          <h6>3.6(B) What are the terms and conditions regarding the Supplier Offers?</h6>
          <div className='inner_content'>
            <p>
              1. Such offers shall be subject to terms and conditions solely determined by us. The terms of these discounts and offers may vary for customers based on factors such as usage of the platform, volume of transactions, time spent on the platform, city, place of residence, and other relevant factors.
            </p>
            <p>
              2. We reserve the right to make the offer available for a limited time period and to void, amend, discontinue, cancel, or reject the use of any of the offers, discounts, or promotional offers, including any aspect or feature of such offers, at any time without prior intimation.
            </p>
            <p>
              3. We reserve the right to vary the timing and dates of the offer, the number, and category of products available for the offer, at any time, subject to our absolute discretion.
            </p>
            <p>
              4. The offers, discounts, and promotional offers may be changed or amended from time to time.
            </p>
            <p>
              5. We reserve the right to make certain offers valid for limited inventory and subject to the availability of the product at the time of booking.
            </p>
            <p>
              6. Certain offers may apply only to products covered under such promotional offers. These offers will operate at Bidsboxx’s discretion on a first-come-first-served basis. Once the offer product stocks are exhausted, you may purchase the same product on the platform outside the promotional offers available.
            </p>
            <p>
              7. Bidsboxx, at its own discretion, reserves the right to cancel your option to return or exchange any product purchased under offers and discounts.
            </p>
            <p>
              8. It is your responsibility to review and comply with the terms and conditions governing the offers, discounts, and other promotional offers provided on our platform.
            </p>
            <p>
              9. Any of the offers, discounts, or promotional offers may not be valid when used in conjunction with other promotional offers or vouchers unless explicitly permitted by us or the Supplier.
            </p>
            <p>
              10. The offers, discounts, and promotional offers cannot be exchanged for cash and can only be availed in accordance with the terms and conditions of the offers unless otherwise communicated.
            </p>
            <p>
              11. Certain offers, discounts, and promotions, such as first order discounts or reactivation discounts, may be applied at the cart level. If multiple products are purchased in a single transaction, such offer, discount, or promotion may be divided and applied to each product in the cart in a manner determined by us.
            </p>
            <p>
              12. Subject to Bidsboxx’s Cancellation and/or Return, Refund, and Replacement policy, if the User: (i) cancels any product subject to promotions, offers, or discounts; or (ii) returns any product purchased under promotions, offers, or discounts, the User will be eligible to receive only the refund of the amount paid for the purchase of such product.
            </p>
            <p>
              13. In the event any product is canceled or returned in accordance with Bidsboxx's Cancellation and/or Return, Refund, and Replacement Policy, then save and except as provided herein, any offer, promotion, or discount applied to such product shall be forfeited.
            </p>
            <p>
              14. We and/or the Supplier shall have no liability with respect to the offers, discounts, and promotional offers on the Platform.
            </p>

          </div></div>


        <h5>4. USE OF THE PLATFORM</h5>
        <div className='content'>


          <h6>4.1 Can Users access and use the Platform at any time, or are there any limitations?</h6>
          <div className='inner_content'>
            <p>
              1. The Company endeavors to make the Application available 24/7. However, the Company does not guarantee that access to the Application will be uninterrupted, timely, error-free, free of viruses, or other harmful components, or that defects will be corrected.
            </p>
            <p>
              2. Users understand and acknowledge that using the Application requires internet connectivity and telecommunication links. Users are responsible for any costs incurred to access and use the Application and avail of Services, and the Company shall not be responsible or liable for such costs under any circumstances.
            </p>
            <p>
              3. The Company does not warrant that the Application will be compatible with all hardware and software used by Users.
            </p>
            <p>
              4. The Application may undergo constant upgrades, and some functions and features may not be fully operational. The Application is provided on an 'as is' and 'as available' basis. The Company expressly disclaims all warranties of any kind, whether express or implied, concerning the records and other data made available to Users.
            </p>
            <p>
              5. Users shall be solely responsible for any damage to their data systems or loss of data arising from downloading content from the Application. No guidance or information, whether written or oral, obtained from the Company or via the Platform, constitutes any warranty unless explicitly stated otherwise.
            </p>

          </div></div>

        <div className='content'>


          <h6>4.2 Does the Company guarantee performance of the agreement or other arrangements between Users or otherwise concerning products on the Platform?</h6>
          <div className='inner_content'>
            <p>
              1. The Company, through the Platform, acts solely as a facilitator of transactions, including those between Suppliers and Users, and is not responsible for any non-performance or breach of contract concerning User Transactions. The Company cannot and does not guarantee that Suppliers will perform any transaction concluded on the Platform. The Company is not required to mediate or resolve any disputes or disagreements between Users or with any third parties.
            </p>
            <p>
              2. The Company does not represent any Users or Suppliers and disclaims any liability regarding any errors or inconsistencies related to information about Suppliers or Users displayed on the Platform.
            </p>
            <p>
              3. The Company makes no representation or warranty regarding the item-specific details (such as legal title, creditworthiness, identity, etc.) of any Users. The Company shall not be liable for any misuse of information shared by Users with it, through Users' profiles, or with third parties on the Platform, chat rooms, forums, or comments.
            </p>
            <p>
              4. Users acknowledge and agree that the Company is not an arbitrator or judge of disputes concerning property and cannot verify whether any Supplier or Reseller selling or supplying merchandise on or through the Platform has the right to sell the products. The Company encourages Users to assist in identifying listings on the Platform that, according to their knowledge or belief, infringe their rights or third-party rights.
            </p>
            <p>
              5. The Company does not take possession of any product offered during any transaction between Suppliers and Users, nor does it gain title to or have any rights or claims over such products. The Company will not hold any right, title, or interest over the products, nor will it have any obligations or liabilities concerning contracts entered into between Users. The Company is not responsible for damages or delays resulting from products being out of stock, unavailable, or back-ordered.
            </p>

          </div></div>
        <div className='content'>
          <h6>
            4.3 Is the use of the Platform (a) restricted in any manner; and (b) does it require any generic compliance from Users?
          </h6>
          <div className='inner_content'>
            <p>
              1. Users should not use the Platform to host, display, upload, download, modify, publish, transmit, update, or share any information which:
            </p>
            <ul>
              <li>a. Belongs to another person and to which the User does not have any right;</li>
              <li>
                b. Is grossly harmful, harassing, blasphemous, defamatory, obscene,
                pornographic, paedophilic, libellous, slanderous, criminally
                inciting, invasive of another’s privacy, hateful, or racially or
                ethnically objectionable, disparaging, related to or encouraging
                money laundering or gambling, or otherwise unlawful in any manner
                whatsoever; or unlawfully threatening or harassing, including but
                not limited to “indecent representation of women” within the meaning
                of the Indecent Representation of Women (Prohibition) Act, 1986;
              </li>
              <li>
                c. Is patently offensive to the online community, such as sexually
                explicit content, or content that promotes obscenity, paedophilia,
                racism, bigotry, hatred, or physical harm of any kind against any
                group or individual;
              </li>
              <li>d. Is harmful to a child or minor;</li>
              <li>e. Harasses or advocates harassment of another person;</li>
              <li>
                f. Infringes upon or violates any third party’s rights, including
                but not limited to intellectual property rights, rights of privacy
                (including unauthorized disclosure of a person’s name, image, email
                address, physical address, or phone number), or rights of publicity,
                including any unauthorized use or posting of any third party's
                social media image without such owner's consent;
              </li>
              <li>
                g. Promotes an illegal or unauthorized copy of another person’s
                copyrighted work, such as providing pirated computer programs or
                links to them, or providing information to circumvent
                manufacturer-installed copy-protect devices;
              </li>
              <li>
                h. Attempts to gain unauthorized access to or exceeds the scope of
                authorized access to the Application or to profiles, blogs,
                communities, account information, or other areas of the Application,
                or solicits passwords or personal identifying information for
                commercial or unlawful purposes from other Users;
              </li>
              <li>
                i. Interferes with another User’s use and enjoyment of the Platform
                or any third party users' enjoyment of similar services;
              </li>
              <li>
                j. Refers to any website or URL that, in the Company's sole
                discretion, contains material that is inappropriate for the Platform
                or any other website, contains content that would be prohibited or
                violates the spirit of these Terms;
              </li>
              <li>k. Violates any law for the time being in force;</li>
              <li>
                l. Deceives or misleads the addressee about the origin of the message
                or knowingly and intentionally communicates any information that is
                patently false or misleading in nature but may reasonably be
                perceived as a fact, including creating a false identity to mislead
                others;
              </li>
              <li>m. Impersonates another person;</li>
              <li>
                n. Threatens the unity, integrity, defense, security, or sovereignty
                of India, friendly relations with foreign states, threatens public
                order, incites the commission of any cognizable offense, or prevents
                the investigation of any offense, or insults other nations;
              </li>
              <li>
                o. Contains software viruses or other computer programming routines
                that may damage, detrimentally interfere with, diminish the value
                of, surreptitiously intercept, or expropriate any system, data, or
                personal information;
              </li>
              <li>
                p. Directly or indirectly offers, attempts to offer, trades, or
                attempts to trade in any item, the dealing of which is prohibited or
                restricted under any applicable law, rule, regulation, or guideline
                in force;
              </li>
              <li>
                q. Is patently false and untrue, written or published in any form,
                with the intent to mislead or harass a person, entity, or agency for
                financial gain or to cause injury to any person.
              </li>
            </ul>

          </div></div>
        <div className='content'>


          <h6> 4.4 When accessing or using the Platform or availing the Services, the User must comply with and ensure the following:</h6>
          <div className='inner_content'>
            <ul>
              <li>All registration information submitted by the User is truthful, lawful, and accurate.</li>
              <li>
                The User's use of the Application/Platform shall be solely for their own
                use, and they shall not authorize others to use their account.
              </li>
              <li>
                The User does not submit, post, upload, distribute, or otherwise make
                available or transmit any information that:
                <ul>
                  <li>
                    a. Is defamatory, abusive, harassing, insulting, threatening, or could
                    be deemed to be stalking or constitute an invasion of another person's
                    right to privacy;
                  </li>
                  <li>b. Is bigoted, hateful, or racially or otherwise offensive;</li>
                  <li>
                    c. Is violent, vulgar, obscene, pornographic, or otherwise sexually
                    explicit;
                  </li>
                  <li>
                    d. Is illegal or encourages or advocates illegal activity or the
                    discussion of illegal activities with the intent to commit them.
                  </li>
                </ul>
              </li>
              <li>
                All necessary licenses, consents, permissions, and rights are owned by the
                Users, and there is no need for any payment, permission, or authorization
                from any other party or entity to use, distribute, or otherwise exploit, in
                all manners permitted by this Agreement, all trademarks, copyrights,
                patents, trade secrets, privacy and publicity rights, and/or other
                proprietary rights contained in any content that Users submit, post,
                upload, distribute, or otherwise transmit or make available.
              </li>
              <li>
                The User will not use the Platform in any way that is unlawful or harms
                Bidsboxx or any other person or entity.
              </li>
              <li>
                The User will not post, submit, upload, distribute, or otherwise transmit
                or make available any software or other computer files that contain a virus
                or other harmful component, or otherwise impair or damage the Platform or
                any connected network, or otherwise interfere with any person or entity’s
                use or enjoyment of the Application.
              </li>
              <li>
                The User will not use another person’s username, password, or other account
                information, or another person’s name, likeness, voice, image, or
                photograph, or impersonate any person or entity or misrepresent their
                identity or affiliation with any person or entity.
              </li>
              <li>
                The User will not attempt to delete or modify any content of the Platform,
                including but not limited to disclaimers or proprietary notices such as
                copyright or trademark symbols or logos.
              </li>
              <li>
                The User will not post or contribute any information or data that may be
                obscene, indecent, pornographic, vulgar, profane, racist, sexist,
                discriminatory, offensive, derogatory, harmful, harassing, threatening,
                embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue,
                political, or contrary to the interests of Bidsboxx.
              </li>
              <li>
                The User shall not access the Platform without authority or use the
                Platform in a manner that damages, interferes with, or disrupts any part of
                the Platform, any equipment or network on which the Platform is stored, or
                any equipment of any third party.
              </li>
              <li>
                The User shall not attempt to gain unauthorized access to any portion or
                feature of the Application, or any other systems or networks connected to
                the Platform by any means. The User shall not probe, scan, or test the
                vulnerability of the Platform nor breach the security or authentication
                measures on the Platform or any network connected to the Platform.
              </li>
              <li>
                The User agrees not to use any device, software, or routine to interfere
                with or attempt to interfere with the proper working of the Platform or any
                transaction being conducted on the Platform, or with any other person’s
                use of the Platform. The User may not use the Platform or any of its
                content for any purpose that is unlawful or prohibited by this Agreement.
              </li>
              <li>
                The User shall at all times ensure full compliance with the applicable
                laws, as amended from time to time, including:
                <ul>
                  <li>(i) the Information Technology Act, 2000 and the rules thereunder;</li>
                  <li>
                    (ii) all applicable domestic laws, rules, and regulations (including
                    provisions of any applicable exchange control laws or regulations in
                    force); and
                  </li>
                  <li>
                    (iii) international laws, foreign exchange laws, statutes, ordinances,
                    and regulations (including, but not limited to Direct and Indirect
                    Taxes applicable as per current statutes in the country) regarding the
                    use of the Application and listing, purchase, solicitation of offers to
                    purchase, and sale of products or Services.
                  </li>
                </ul>
              </li>
              <li>
                The User shall not engage in any transaction prohibited by the provisions
                of any applicable law, including exchange control laws or regulations for
                the time being in force.
              </li>
              <li>
                To allow Bidsboxx to use the information supplied by Users without
                violating any rights or laws, Users agree to grant Bidsboxx a non-exclusive,
                worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through
                multiple tiers) right to exercise the copyright, publicity, database
                rights, or any other rights. Bidsboxx will only use the information in
                accordance with this Agreement, applicable to the use of the Platform, and
                for the provision of Services.
              </li>
              <li>
                Bidsboxx reserves the right to disable any user identification code or
                password if any User has failed to comply with any of the provisions of
                this Agreement. Bidsboxx has all rights to take necessary action and claim
                damages that may occur due to the User's involvement or participation,
                whether intentionally or unintentionally, in hacking activities either on
                their own or through groups of people.
              </li>
            </ul>

          </div></div>

        <div className='content'>

          <h6>4.5 Is information related to products on the Platform provided by the Company?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Not all information on the Platform is provided by Bidsboxx. From time to time, Users who are Suppliers provide information relating to the products proposed to be sold by them and are hence responsible for the same. In this connection, Suppliers undertake that all such information shall be accurate in all respects. Suppliers are discouraged from and should not exaggerate or overemphasize the attributes of such products so as to mislead Users in any manner.
              </li>
              <li>
                2. Bidsboxx reserves the right, but has no obligation, to monitor the materials posted on the Platform. Bidsboxx, however, has the right to remove or edit any content that, in its sole discretion, violates, or is alleged to violate, any applicable law or the spirit of these Terms. In no event shall Bidsboxx assume any responsibility or liability for any content posted or for any claims, damages, or losses resulting from the use of content and/or appearance of content on the Platform.
              </li>
              <li>
                3. Suppliers take sole responsibility for the correctness of the details pertaining to specifics (such as quality, value, saleability, etc.) of the products proposed to be sold or offered to be sold or purchased on the Platform. Bidsboxx does not implicitly or explicitly support or endorse the sale or purchase of any products nor provide any warranty/guarantee of the products sold to Users, and in no event shall such products be the responsibility of Bidsboxx. Bidsboxx does not represent or warrant that the information available on the Platform will be correct, accurate, or otherwise reliable.
              </li>
              <li>
                4. Bidsboxx is not responsible for any inaccuracy, incompleteness, or outdated information made available on the Application, whether provided by any User including Suppliers.
              </li>
            </ul>

          </div></div>


        <h5>5. LISTING AND SELLING</h5>
        <div className='content'>

          <h6>5.1 As a Supplier, in addition to this Agreement, what other terms is a User required to abide by?</h6>
          <div className='inner_content'>

            <ul>
              <li>
                1. Suppliers, in addition to this Agreement, are also bound by applicable laws of India, including without limitation, the following laws:
                <ul>
                  <li>1. The Legal Metrology Act, 2009 and Legal Metrology (Packaged Commodities) Rules, 2011 (Packaging Rules);</li>
                  <li>2. Drugs and Cosmetics Act, 1940 and Drugs and Cosmetics Rules, 1945 (D&C Rules);</li>
                  <li>3. The Consumer Protection (E-Commerce) Rules, 2020;</li>
                  <li>
                    4. Food Safety and Standard Act, 2006, Food Safety and Standard
                    (Packaging and Labelling) Regulation 2011 (FSS Packaging
                    Regulation), Food Safety and Standard (Food Product Standard and
                    Food Additives) Regulation, 2011 (FSS Standard Regulations), and
                    Food Safety and Standard (Food or Health Supplements,
                    Nutraceuticals, Food for Special Medical Purpose, Functional
                    Food, and Novel Food) Regulation 2016 (FSS Supplement Regulation).
                  </li>
                </ul>
              </li>
              <li>
                2. As per the above-mentioned statutes and regulations and any other relevant law in place during the tenure of this association, Bidsboxx understands that there is an obligation on the Supplier to ensure that the package in which the products are sold complies with labelling and packing requirements and other laws that may be prescribed in this regard. Hence, it shall be the sole responsibility of the Supplier to comply with applicable laws, and Bidsboxx shall not be held responsible in any manner. Suppliers shall indemnify Bidsboxx and the Platform for any harm or loss in relation to contravention of the above regulations or other applicable laws.
              </li>
            </ul>
          </div></div>


        <div className='content'>


          <h6>5.2 When can the Suppliers get their products listed?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Suppliers are permitted to list products for sale on the Application in accordance with the terms mentioned in this Agreement and any other contract entered into with Bidsboxx for the said purpose, wherein other rights and obligations of the parties are defined in detail.
              </li>
              <li>
                2. By listing their products on the Platform, the Suppliers represent and warrant that they are legally capable of selling or listing the products on the Platform; and that the listed items do not infringe upon the intellectual property, trade secret, or other proprietary rights or rights of publicity or privacy rights of any third party. For clarity, Suppliers shall refrain from using any third party's images available over any social media without such owner's consent. Suppliers and Users agree that Bidsboxx is not responsible for the breach of the same.
              </li>
            </ul>

          </div></div>

        <h5>6. USER INFORMATION AND THIRD-PARTY TOOLS/LINKS</h5>
        <div className='content'>

          <h6>6.1 What information is collected from the User? How does Bidsboxx deal with the information provided to it by a User while using the Platform?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Bidsboxx collects various types of information, including both non-personal and personal information.
              </li>
              <li>
                2. All information about Users that is collected, stored, or transmitted in any way on the Platform is processed to facilitate various operations on the Platform, including registration, order placement, listing, or payments.
              </li>
              <li>
                3. For a more comprehensive understanding, Users are encouraged to view the Platform’s Privacy Policy available on the Platform.
              </li>
            </ul>

          </div></div>

        <div className='content'>

          <h6>6.2 Does Bidsboxx use Third-Party tools on the Platform?</h6>
          <div className='inner_content'>

            <ul>
              <li>
                1. Bidsboxx may provide Users with access to third-party tools on the Platform that Bidsboxx neither monitors nor controls. Users acknowledge and agree that access to such tools is on an 'as is' and 'as available' basis, without any warranties, representations, or conditions of any kind and without any endorsement by Bidsboxx. Bidsboxx shall have no liability whatsoever arising from or relating to the use of optional third-party tools.
              </li>
              <li>
                2. Any use by the User of the optional tools offered through the Platform is entirely at their own risk and discretion, and it is the responsibility of the User to ensure that they are familiar with and approve the terms on which such tools are provided by the relevant third-party provider(s).
              </li>
              <li>
                3. Bidsboxx may from time to time offer new features through the Platform which may include the use of new third-party tools and resources. Such new features shall also be subject to this Agreement. Complaints, claims, concerns, or questions regarding third-party tools or third-party websites should be directed to the third-party.
              </li>
            </ul>

          </div></div>

        <div className='content'>


          <h6>6.3 Does Bidsboxx use third-party links or third-party tools on the Platform? Are these links and tools accurate and secure?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Certain content or products available via the Platform may include materials from third parties. Third-party links on the Platform may direct Users to third-party websites that are not affiliated with Bidsboxx. Bidsboxx is not responsible for examining or evaluating the content or accuracy and does not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties.
              </li>
              <li>
                2. Bidsboxx is not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites regardless of the existence of any third-party link on the Platform. Please review carefully such third-party’s policies and practices and make sure to understand them before engaging in any transactions.
              </li>
            </ul>

          </div></div>

        <h5>7. INTELLECTUAL PROPERTY (IP) AND IP INFRINGEMENT</h5>
        <div className='content'>

          <h6>7.1 Can Users use the content published on the Platform such as the "Bidsboxx" mark when doing business with other parties?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Users may not use any trademark, service mark, or logo of any independent third parties without prior written approval from such parties.
              </li>
              <li>
                2. "Bidsboxx" and related icons and logos, whether registered or unregistered, are the trademarks of Bidsboxx and are protected under applicable copyright, trademark, and other proprietary and intellectual property laws. Users’ unauthorized adoption, copying, modification, use, or publication of these marks is strictly prohibited.
              </li>
              <li>
                3. Users must not modify the paper or digital copies of any materials printed or downloaded in any way, and they must not use any illustrations, photographs, video or audio sequences, or any graphics separately from any accompanying text.
              </li>
              <li>
                4. Users must not use any part of the materials on the Platform for commercial purposes without obtaining a license to do so from Bidsboxx. All rights not otherwise claimed under this Agreement by Bidsboxx are hereby reserved.
              </li>
              <li>
                5. Users understand that the Platform and software embodied within the Platform may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by Bidsboxx or other parties that facilitate the same. Users agree that they will not attempt to override, disable, circumvent, or otherwise interfere with any such security components and usage rules embedded in the Platform.
              </li>
            </ul>
          </div></div>
        <div className='content'>
          <h6>7.2 How does Bidsboxx deal with IP infringement?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Any trademark, word mark, or intellectual property of any User(s) or Supplier(s) belongs to such User(s)/Supplier(s) alone, and Bidsboxx has no right or claim over the same.
              </li>
              <li>
                2. Bidsboxx reserves the right, in its sole discretion, to remove any material/content/photos/offers displayed on the Platform which, in Bidsboxx’s reasonable belief, is unlawful, could subject Bidsboxx to liability, is in violation of this Agreement, or is otherwise found inappropriate in Bidsboxx’s opinion. Bidsboxx reserves the right to cooperate with any investigation in this regard.
              </li>
              <li>
                3. Bidsboxx reserves the right to suspend or terminate the account of a User as deemed appropriate. Users agree that Bidsboxx shall have no liability to any Users, including liability in respect of consequential or any other damages, in the event Bidsboxx takes any of the actions pursuant to allegations of IP infringement.
              </li>
              <li>
                4. Users acknowledge and agree that Bidsboxx is not an arbitrator or judge of disputes concerning intellectual property and cannot, by any means, verify that any Supplier selling or supplying merchandise on the Platform has the right to sell the products. Bidsboxx encourages Users to assist in identifying listings on the Platform that, according to Users’ knowledge or belief, infringe their rights or third-party rights.
              </li>
              <li>
                5. The delisting of a product from the Platform is to safeguard Bidsboxx’s interests. By taking down a listing, Bidsboxx does not and cannot be deemed to be endorsing a claim of infringement. Further, in instances where Bidsboxx declines to take down a listing, Bidsboxx does not and cannot be deemed to be endorsing that the listing is not infringing third-party rights or endorsing any sale or supply of merchandise or services pursuant to or on account of such listing.
              </li>
              <li>
                6. We request you to review the Intellectual Property Policy available on the Application for more information.
              </li>
            </ul>

          </div></div>


        <h5>8. DISCLAIMER AND LIABILITIES</h5>
        <div className='content'>

          <h6>8.1 What are the standard disclaimers in relation to the Platform and the Services?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Bidsboxx, in no event, is or will be liable to the User, including the Reseller or anyone claiming through a User, in respect of product or other User transactions under contract, negligence, strict liability, or other legal or equitable theory for any special, incidental, indirect, consequential, exemplary, or punitive damages, loss of goodwill, loss of revenue, loss of opportunity, loss of anticipated profits, whatsoever, including those resulting from loss of use, data, or profits, whether or not foreseeable or whether or not Bidsboxx has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty or negligence or any other claim arising out of or in connection with the use of or access to the Platform.
              </li>
              <li>
                2. Bidsboxx shall not be liable for: any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation, any financial losses, loss of data, replacement costs, or any similar damages, whether based in contract, tort, strict liability, or otherwise, arising from the use of the Platform, or for any other claim related in any way to the use of the Application, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Application/Platform or any content posted, transmitted, or otherwise made available via the Application/Platform, even if advised of their possibility.
              </li>
              <li>
                3. Users shall be solely responsible for damages, if any, to their data system or for loss of data arising from the download of content from the Platform. No guidance or information, written or oral, obtained from Bidsboxx or via the Platform, shall constitute any warranty, unless stated otherwise.
              </li>
              <li>
                4. The Suppliers listing their products on the Platform are solely responsible for the accuracy of product information. Users shall solely be responsible for using the products in a safe and legal manner. Bidsboxx shall not assume any loss, claims, damages, or injury that may arise from the violent or illegal use or misuse of the product sold by the Suppliers over the Platform.
              </li>
            </ul>
          </div></div>
        <div className='content'>

          <h6>8.2 What are the disclaimers regarding advertisements (including any information or offer thereunder) contained on, distributed through, or linked, downloaded, or accessed from the Platform (“Advertisements”)?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                a. Advertisements on the Platform are intended solely to provide general information for the personal use of the User(s). Bidsboxx does not represent, warrant, or endorse in any manner the accuracy or reliability of the Advertisements. Bidsboxx accepts no responsibility or liability in relation to the Advertisements, including without limitation on account of your use or reliance placed by you on such Advertisements.
              </li>
              <li>
                b. The Advertisements on the Platform are advertised/displayed at the behest of the advertisers. Bidsboxx does not create such content and neither does it exercise any control over the content that is displayed by the advertisers. The advertisers are third parties over which Bidsboxx does not have any direct or indirect control. Bidsboxx does not make any representation, warranty, recommendation, or guarantee in respect of the content of the Advertisements as well as its subject matter and the products/services being advertised (including without limitation with respect to suitability, merchantability, reliability, availability, or quality of the product/service), nor does Bidsboxx implicitly or explicitly support or endorse the sale or purchase of any products/services which are the subject matter of the Advertisements or are referred to therein. Bidsboxx accepts no liability for any error, inaccuracy, or omission of third parties and advertisers in this regard.
              </li>
            </ul>

          </div></div>


        <div className='content'>


          <h6>8.3 What are the disclaimers regarding advertisements (including any information or offer thereunder) of products listed on the Platform and shown on other media and forums (including print media, digital platforms, etc.) (“Other Advertisements”)?</h6>
          <div className='inner_content'>
            <ul>
              <li>1. The images of the products shown in Other Advertisements are for illustrative purposes and creative representation only and may vary from the actual products.</li>
              <li>2. Unless otherwise mentioned, all the product-related deals/discounts mentioned in Other Advertisements are offered by the participating sellers/brands to the total exclusion of Bidsboxx.</li>
              <li>3. All Other Advertisements with details of starting prices for a product category are for reference only, and the actual price of the product may vary.</li>
              <li>4. The scenes, characters, and references in brand films and videos are purely fictional and only for representational purposes.</li>
              <li>5. The offers, discounts, and other promotional offers displayed in Other Advertisements may vary for customers based on factors such as usage of the platform, volume of transactions, time spent on the platform, city, place of residence, and time.</li>
              <li>6. Bidsboxx and/or the Suppliers reserve the right to amend or discontinue any of the offers/deals/discounts at their sole discretion and without prior intimation to any User.</li>
              <li>7. Bidsboxx accepts no responsibility or liability for inaccuracies in the data displayed in the Other Advertisements. Note that any data displayed in relation to the products sold on Bidsboxx may not be the most recent and may be aggregated over a period of time.</li>
            </ul>

          </div></div>


        <div className='content'>

          <h6>8.4 What happens to User orders in case of a lockdown or other force majeure event?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. Bidsboxx shall not be liable for any damages whatsoever arising out of force majeure or other similar circumstances, directly or indirectly affecting Bidsboxx and/or the Platform. Examples include, but are not limited to, labor disputes, governmental actions, war, sabotage, civil unrest, fire, storms, floods, earthquakes, epidemics or pandemics, resource limitations, or network defects.
              </li>
              <li>2. Force majeure or other events beyond Bidsboxx’s control may lead to the suspension or limitation of the Platform until further notice.</li>
            </ul>

          </div></div>


        <div className='content'>

          <h6>8.5 Under what circumstances may a User be liable for any damages to Bidsboxx?</h6>
          <div className='inner_content'>
            <ul>
              <li>
                1. The User shall indemnify, defend, and hold harmless Bidsboxx, its subsidiaries, affiliates, officers, employees, and service providers from any losses, liabilities, claims, penalties, damages, or costs that arise due to:
                <ul>
                  <li>a. Breach of this Agreement by the User;</li>
                  <li>b. Claims made by third parties due to the User's use of the Platform;</li>
                  <li>c. Violation of third-party rights, including intellectual property rights;</li>
                  <li>d. Violation of applicable laws.</li>
                </ul>
              </li>
            </ul>

          </div></div>


        <h5>9. COMMUNICATION</h5>
        <div className='content'>

          <h6>9.1 How to contact Bidsboxx in case of any queries regarding this Agreement or grievances relating to the Platform?</h6>

          <div className='inner_content'>
            <p>
              Users can contact Bidsboxx for queries or grievances at the following:
            </p>
            <ul>
              <li>Email: <a href="mailto:support@bidsboxx.com">bidsboxx@gmail.com</a></li>
              <li>Phone: +917419741977</li>
            </ul>
            <h6>Address of Principal Place of Business:</h6>
            <address>
              Building No./Flat No.: 37/40<br />
              Nearby Landmark: Shivaji Park<br />
              Locality/Sub Locality: Model Town<br />
              City/Town/Village: Yamuna Nagar<br />
              District: Yamuna Nagar<br />
              State: Haryana<br />
              PIN Code: 135001<br />
            </address>

          </div></div>


        <p>
          {/* For further assistance, please visit our <a href="/contact-us">Contact Us</a> page on the Platform. */}
        </p>

      </div>
    </div>
  )
}

export default TermsConditions