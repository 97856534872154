import React, { useEffect, useState, useReducer } from 'react';
import { SignOut } from '../../Svg';
import { apiService } from '../../Services/ApiServices';
import { useNavigate } from 'react-router';
import { notify } from '../../CommonComponent/Toaster';
import { confirmAlert } from 'react-confirm-alert';
import BaseLoader from '../../CommonComponent/BaseLoader';

function Settings() {
  const navigate = useNavigate();
  let token = localStorage.getItem('token');
  const [showNotification, setShowNotification] = useState({});

  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true
    }
  );

  const logout = () => {
    try {
      let token = localStorage.getItem('token')
      if (!token) {
        navigate("/login");
      }
      apiService.logout(token).then(result => {
        localStorage.clear();
        sessionStorage.clear();
        if (result.data?.status_code == 0) {
          navigate("/login")
        }
        else {
          notify("success", result?.data?.response_data?.message);
          navigate("/login")
        }
      })
    } catch (e) {
      console.error('e', e);
    }
  }

  const settingNotification = (name, toggleValue) => {
    try {
      const req = {
        "push_notification": name == "Push Notification" ? toggleValue : showNotification?.push_notification,
        "email_notification": name == "Email Notification" ? toggleValue : showNotification?.email_notification,
        "sms_notification": name == "SMS Notifications" ? toggleValue : showNotification?.sms_notification
      }
      apiService.settingNotification(req, token).then(result => {
        if (result?.data?.status_code == 1) {
          getSettingNotification()
        }
        else {
          if (result?.data?.status_code != 1) {
            // notify("err", result?.data?.error?.message?.error);
          }
        }
      })
    } catch (e) {
      console.error('e', e);
    }
  }

  const getSettingNotification = () => {
    try {
      setUserInput({ isLoader: true });
      apiService.getSettingNotification(token).then(result => {
        setUserInput({ isLoader: false });
        if (result?.data?.status_code == 1) {
          setShowNotification(result?.data?.response_data?.notification);
        }
        else {
          if (result?.data?.status_code != 1) {
            // notify("err", result?.data?.error?.message?.error);
          }
        }
      })
    } catch (e) {
      console.error('e', e);
    }
  }

  const deleteSeller = () => {
    try {
      if (!token) {
        navigate("/");
      }
      apiService.deleteSeller(token).then(result => {
        localStorage.clear();
        sessionStorage.clear();
        if (result.data?.status_code == 0) {
          navigate("/")
        }
        else {
          navigate("/")
        }
      })
    } catch (e) {
      console.error('e', e);
    }
  }

  const deleteSellerSubmit = () => {
    confirmAlert({
      title: 'Delete Account',
      message: 'Are you sure you want to delete this account?',
      buttons: [
        {
          label: 'YES',
          onClick: () => deleteSeller()
        },
        {
          label: 'NO'
        }
      ]
    });
  };

  const settingListData = [
    {
      name: "Push Notification",
      toggle: showNotification?.push_notification
    },
    {
      name: "Email Notification",
      toggle: showNotification?.email_notification
    },
    {
      name: "SMS Notifications",
      toggle: showNotification?.sms_notification
    }
  ]

  useEffect(() => {
    getSettingNotification()
  }, [])


  const logoutWithConfirmation = () => {
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to sign out?',
      buttons: [
        {
          label: 'YES',
          onClick: () => logout(), // Call the logout function on confirmation
        },
        {
          label: 'NO',
        }
      ]
    });
  };

  return (
    <div className='dashboard_inner'>
     
        {userInput.isLoader ? <BaseLoader />
          : (
            <>
             <div className='_setting_section'>
              {settingListData.map((item) => {
                return (
                  <div className='_setting_box'>
                    <h6>{item.name}</h6>
                    <div className='salon_switch_container'>
                      <label className="switch" >
                        <input type="checkbox" checked={item.toggle} onClick={() => settingNotification(item.name, !item.toggle)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                )
              })}

              <div className='_setting_box ' >
                <h6>Delete Account</h6>
                <div className='salon_switch_container' >
                  <span className="" onClick={deleteSellerSubmit} style={{ color: "#F17721", cursor: "pointer" }}>Delete</span>
                </div>
              </div>
              <div className='text-end'>
                <button className='btn product_btn lower_case go_live_btn common_button avenir_heavy' onClick={logoutWithConfirmation}>
                  <span className='me-2'><SignOut /></span> SIGN OUT
                </button>
              </div>
              </div>
            </>
          )
        }
     
    </div>
  )
}

export default Settings
